import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { ELink } from '~/ExternalLink';


export const IntervalEstimationMeta = {
    title: "Intervalové odhady",
    shortTitle: "Intervalové odhady",
    path: Path.interval_estimation,
    element: (sectionNumber:string) => <IntervalEstimation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
];


export function IntervalEstimation({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={IntervalEstimationMeta.title} TOCSpec={TOCSpec}>

            {/* <h2 id="summary">Shrnutí</h2> */}

            <p><i>Připravujeme...</i></p>

            <p>

            Viz přednášky (poznámky, videa), a nebo např.

            &nbsp;<ELink href={"https://www.youtube.com/watch?v=EJe3jiZNwUU"}>youtube 1</ELink>,

            &nbsp;<ELink href={"https://www.youtube.com/watch?v=9GtaIHFuEZU"}>youtube 2</ELink>,

            &nbsp;<ELink href={"https://www.youtube.com/watch?v=BQ88ni4bJNA"}>youtube 3</ELink>,

            <br/>nebo

            &nbsp;<ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/04-inerval-odhady.html"}>kurz MUNI</ELink>

            &nbsp;(matematičtější),

            nebo
            &nbsp;<ELink href={"https://www.stat.berkeley.edu/~stark/SticiGui/Text/confidenceIntervals.htm"}>Stark</ELink>.

            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

