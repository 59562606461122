import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { ELink } from '~/ExternalLink';


export const LinearRegressionMeta = {
    title: "Lineární regrese",
    shortTitle: "Lineární regrese",
    path: Path.linear_regression,
    element: (sectionNumber:string) => <LinearRegression sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
];


export function LinearRegression({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={LinearRegressionMeta.title} TOCSpec={TOCSpec}>

            {/* <h2 id="summary">Shrnutí</h2> */}

            <p><i>Připravujeme...</i></p>

            Viz přednášky (poznámky, videa), nebo např.

            &nbsp;<ELink href={"https://www.youtube.com/watch?v=eYTumjgE2IY"}>youtube</ELink>,

            &nbsp;nebo

            &nbsp;<ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/06-regresni-analyza.html"}>kurz MUNI</ELink>

            &nbsp;(matematičtější).

            <Footer/>
        </ChapterWrapper>
    );
}

