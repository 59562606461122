import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const PointEstimationMeta = {
    title: "Bodové odhady",
    shortTitle: "Bodové odhady",
    path: Path.point_estimation,
    element: (sectionNumber:string) => <PointEstimation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "pointest-summary",
    "pointest-motivation",
    "pointest-pointest",
    "pointest-params",
    "pointest-common",
];


export function PointEstimation({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={PointEstimationMeta.title} TOCSpec={TOCSpec}>

            Odhady parametrů lze pojednat více či méně technicky. Matematicky pokročilé pojednání naleznete pro referenci např.&nbsp;
            <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/02_01-odhady-parametru.html"}>zde</ELink>
            &nbsp; a <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/03_konstrukce-bodovych-odhadu.html"}>zde</ELink>.

            <p><i>[Připravujeme - toto je rozpracovaný stav.]</i></p>

            <h2 id="pointest-summary">Shrnutí</h2>

            {/* <p>Dejme tomu, že máme nějakou populaci, na které sledujeme hodnotu nějakého atributu.
            Když náhodně vybereme jeden prvek z této populace, získáme konkrétní hodnotu tohoto atributu
            - konkrétní hodnotu náhodné veličiny.
            </p> */}

            <h2 id="pointest-motivation">Motivace</h2>

            <p>Když děláme náhodný pokus, předpokládáme, že se řídí nějakým rozdělením pravděpodobnosti,
            o kterém sice můžeme mít nějaké povědomí, ale to ještě neznamená, že ho známe přesně.
            </p>

            <p>Např. při změření velikosti rychlosti náhodně zachycené částice z daného objemu ideálního plynu
            máme představu, že rychlost bude dána typově Maxwell-Boltzmannovým rozdělením, ale pokud
            neznáme teplotu plynu, neznáme rozdělení rychlostí přesně - neznáme střední hodnotu, rozptyl - tj. kde je "posazené".
            </p>
            <p>Při opakovaném měření nějaké veličiny na stejném vzorku máme představu, že rozdělení chyb
            je normální, se střední hodnotou 0 (nemáme systematickou chybu), ale neznáme rozptyl (jak
            moc je měřicí aparát citlivý na různé vnější mikro-vlivy, které náhodně kolísají).
            Ve vzorci pro hustotu normálního rozdělení zůstává neznámý parametr {"$\\sigma$"} - rozdělení známe
            kvalitativně (normální), ale ne kvantitativně (nevíme co dosadit za {"$\\sigma$"}).
            </p>
            <p>Dejme tomu, že v klobouku je 100 lístků; a jsou to buď výherní lístky (mají na sobě číslo 1),
            nebo nevýherní lístky (mají na sobě číslo 0). Při výběru z populace lístků (tahání lístku z klobouku)
            je jasné, že vybraná hodnota se typově řídí alternativním rozdělením pravděpodobnosti. Jen nevíme,
            jaký je poměr zastoupení výherních lístků v celku.
            Kdybychom si po jednom prohlédli celou populaci (vytáhli z klobouku všechny lístky),
            pak bychom o rozdělení získali 100% přesnou představu.
            Pokud je tam např. 10 výherních a 90 nevýherních lístků, pak bychom věděli,
            že číslo na lístku (při náhodném vytažení) má alternativní rozdělení
            s parametrem {"$p=0.1$"} (s pravděpodobností výhry 0.1). Pokud si ale všechny lístky neprohlédneme,
            tak sice víme, že rozdělení je (typově) alternativní, ale už nevíme kolik je
            parametr {"$p$"} - tedy jak vypadá rozdělení přesně.
            </p>
            <p>V populaci všech lidí v republice nás zajímá rozložení věku. Náhodná veličina
            je věk náhodně vybraného člověka. Dokud se nezeptáme úplně každého člověka, pak
            rozdělení věku v populaci nemáme 100% přesně. Může nás zajímat celé rozdělení (demografická křivka),
            nebo jen nějaký jeho parametr (např. střední hodnota věku, nebo procento lidí starších 65 let).
            </p>

            <h2 id="pointest-pointest">Bodové odhady</h2>

            <p>Lidově řečeno, bodový odhad je nějaká operace na jednom nebo více měřeních, která nám dává
            nějakou hodnotu, kterou považujeme za odhadovanou hodnotu parametru, který nás zajímá.
            Například průměrnou energii částic v plynu (odhadovaný parametr), můžeme odhadnout
            jako průměr energií zachycených 100 částic.
            </p>

            <p>Pokud budeme měření (tahání z klobouku, výběr z populace) opakovat, budeme dostávat
            pokaždé jinou hodnotu odhadu - to znamená že odhad je náhodná veličina.
            </p>

            <p>Matematicky je bodový odhad <strong>statistika</strong>, tj. náhodná veličina kterou získáme
            jako funkci náhodného výběru. Například výběrový průměr {"$\\bar{X}$"} je odhadem střední hodnoty {"$\\mu$"}.
            </p>

            <p>Aby byl odhad užitečný, měl by ideálně dávat hodnoty blízké skutečné (ale neznámé) hodnotě daného
            parametru.
            </p>

            <p>
            Také se hodí, aby odhad byl <strong>nestranný</strong>, tj. aby měl střední hodnotu rovnou skutečné hodnotě
            parametru. Víme třeba, že  {"$E(S_n^2) = \\frac{n-1}{n}\\sigma^2$"} - tj. nekorigovaná verze
            výběrového rozptylu má střední hodnotu menší než rozptyl {"$\\sigma^2$"} rozdělení, ze kterého děláme výběr.
            To znamená, že pokud bychom statistiku {"$S_n^2$"} používali jako odhad rozptylu, pak bychom
            skutečnou hodnotu rozptylu systematicky podhodnocovali.
            Nebyl by to tedy nestranný odhad - byl by <strong>vychýlený</strong>.
            (Proto také používáme korigovanou verzi {"$S^2$"}.)
            </p>

            <p>
            Technicky vzato, libovolnou statistiku {"$T$"} můžeme označit jako odhad libovolného
            parametru {"$\\theta$"} - a to i když je 'úplně mimo'. Aby tento odhad ale byl užitečný, mělo by platit
            že {"$E(T) = \\theta$"} - tj. odhad je nestranný - a taky že rozptyl odhadu (tj. {"$D(T)$"})
            je co nejmenší (aby odhad nevycházel jednou tak jednou tak - s velkým rozptylem).
            U některých rozdělení a/nebo parametrů tyto požadavky nelze z principu přesně splnit,
            takže se někdy jen požaduje, aby střední hodnota byla alespoň blízko skutečné hodnotě parametru,
            nebo aby se k ní asymptoticky blížila s rostoucí velikostí výběru.
            </p>

            <h2 id="pointest-params">Odhadované parametry</h2>
            <p>Typicky se odhaduje střední hodnota {"$\\mu$"} a rozptyl {"$\\sigma^2$"}.
            Můžeme však odhadovat i jiné charakteristiky -
            např. hodnoty distribuční funkce (jaké procento lidí v populaci je starších 65 let),
            kvantily (pod jakým věkem je 50% populace), pravděpodobnosti (procento lidí mezi 20 a 30 lety),
            koeficienty v lineární regresi, atd.
            </p>
            <p>
            Pojem "parametr" zde může být mírně matoucí, protože se (možná na první pohled) používá v mírně jiném
            významu než při definici parametrizované rodiny rozdělení. Zde parametrem myslíme
            nejen "vstupní" parametr, kterým se běžně parametrizuje daná rodina rozdělení, ale i
            nějakou odvozenou (spočítanou) charakteristiku rozdělení. Ukážeme si to na příkladech:
            </p>

            <p>Exponenciální rozdělení má jeden parametr {"$\\theta$"}:
            {`$$
                f(x;\\theta) = \\theta e^{-\\theta x}
            $$`}
            Střední hodnota je {"$\\mu = \\frac{1}{\\theta}$"} a rozptyl je {"$\\sigma^2 = \\frac{1}{\\theta^2}$"}.
            Můžeme odhadovat jak parametr {"$\\theta$"} (který se v definici explicitně objevuje),
            tak střední hodnotu a rozptyl ("spočítané parametry").
            Lze se na to podívat i tak, že si rozdělení můžeme "přeparametrizovat", aby bylo vyjádřeno
            pomocí daného parametru - zde např. pomocí střední hodnoty:
            {`$$
                f(x, \\mu) = \\frac{1}{\\mu} e^{-\\frac{x}{\\mu}},
            $$`}
            nebo rozptylu:
            {`$$
                f(x, \\sigma^2) = \\frac{1}{\\sqrt{\\sigma^2}} e^{-\\frac{x}{\\sqrt{\\sigma^2}}}.
            $$`}
            </p>

            <h2 id="pointest-common">Běžné bodové odhady</h2>

            <p>
            Když rozdělení závisí na parametru {"$\\theta$"}, pak i jeho střední hodnota bude v principu
            záviset na {"$\\theta$"}, což indikujeme zápisem {"$\\mu_{\\theta}$"}.
            Střední hodnota výběrového průměru počítaná např. za pomocí hustoty bude také záviset
            na parametru {"$\\theta$"}, což indikujeme zápisem {"$E_{\\theta}$"}.
            <br/>
            <br/>
            Střední hodnota výběrového průměru je odhadem střední hodnoty rozdělení, ze kterého děláme výběr:
            {`$$
                E_{\\theta} \\bar{X_n} = \\mu_{\\theta}.
            $$`}

            V příkladu nahoře je {"$\\mu_{\\theta} = \\frac{1}{\\theta}$"}.
            {/* Kdybychom to počítali explicitně na výběru velikosti {"$n=2$"}, bude to vypadat takto:
            {`$$\\begin{align*}
                E_{\\theta}(\\bar{X_2}) &= \\frac{1}{2} \\int_{0}^{\\infty} \\int_{0}^{\\infty} x_1 x_2 f(x_1, \\theta) f(x_2, \\theta) dx_1 dx_2 = \\\\
                                        &= \\frac{1}{2} \\int_{0}^{\\infty} \\int_{0}^{\\infty} x_1 x_2 \\theta e^{-\\theta x_1} \\theta e^{-\\theta x_2} dx_1 dx_2 = \\\\
            \\end{align*}$$`} */}

            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

