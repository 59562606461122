import React from 'react';

import { Path } from '~/paths';
import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';

const density1 = require('../assets/images/density1.png');
const density2 = require('../assets/images/density2.png');

const density_unif = require('../assets/images/density_unif.png');
const density_linear = require('../assets/images/density_linear.png');
const density_comparison = require('../assets/images/density_comparison.png');

const density_explanation = require('../assets/images/density_explanation.png');

export const DensityMeta = {
    title: "Hustota pravděpodobnosti",
    shortTitle: "Hustota",
    path: Path.density,
    element: (sectionNumber:string) => <Density sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "density-principle",
    "density-observations",
    "density-uniform",
    "density-linear",
    "density-analogy",
    "density-general",
];

export function Density({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={DensityMeta.title} TOCSpec={TOCSpec}>

            <h2 id="density-principle">Princip</h2>

            <p>Hustota rozdělení na {"$\\mathbb{R}$"} je taková nezáporná funkce {"$f$"}, že
            {`$$
                P(\\langle a, b \\rangle) = \\int_a^b f(x) \\text{d} x.
            $$`}
            Integrál funkce je plocha pod jejím grafem - pravděpodobnost je tedy plocha:
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density1} style={{width: "55%"}}/>
            </div>

            <p>
            Pokud pracujeme v {"$\\mathbb{R}^n$"}, pak
            {`$$
                P(A) = \\int_A f(x) \\text{d} x,
            $$`}
            kde {"$A$"} je nějaká oblast, přes kterou integrujeme vícerozměrným integrálem. V {"$\\mathbb{R}^2$"} je
            hustota funkcí dvou proměnných ({"$f(x,y)$"}), integrál je dvojitý, a pravděpodobnost je objem pod grafem hustoty nad oblastí {"$A$"}:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density2} style={{width: "55%"}}/>
            </div>

            <p><i>Pozn.: V základnější matematice se v {"$\\mathbb{R}$"} určitý integrál zavádí
            jako Riemannův integrál, a jen pro intervaly
            - integrál od {"$a$"} (dolní mez) do {"$b$"} (horní mez).
            V {"$\\mathbb{R}^n$"} pak pro nějaké souvislé oblasti. Tohle ale zdaleka nejsou všechny (borelovské)
            množiny. <strong>Lebesgueův integrál</strong>&nbsp;
            (<ELink href={"https://en.wikipedia.org/wiki/Lebesgue_integration"}>Wikipedie</ELink>)
            představuje elegantní teorii,
            která řeší integraci jedním unifikovaným způsobem pro všechny (měřitelné) množiny,
            pro všechny dimenze {"$\\mathbb{R}^n$"}, pro obecné množiny {"$\\Omega$"} (nejen pro {"$\\mathbb{R}$"}),
            a zahrnuje v sobě i diskrétní rozdělení; suma se stává speciálním případem integrálu.
            Je to tradiční východisko pro studium pravděpodobnosti.
            My ale jeho znalost nepředpokládáme, a nemáme prostor ji budovat (to by byla náplň samostatného kurzu).
            </i></p>

            <p>Pracujme nyní v {"$\\mathbb{R}$"}.
            Mějme dva navazující intervaly {"$\\langle a, b \\rangle$"} a {"$\\langle b, c \\rangle$"}. Víme že:
            {`$$
                \\int_{\\textcolor{blue}{a}}^{\\textcolor{blue}{c}} f(x) \\text{d} x = \\int_{\\textcolor{blue}{a}}^{\\textcolor{red}{b}} f(x) \\text{d} x + \\int_{\\textcolor{red}{b}}^{\\textcolor{blue}{c}} f(x) \\text{d} x.
            $$`}
            Což je vyjádřením toho, že velikost plochy složené ze dvou disjunktních částí je součtem velikostí ploch
            jednotlivých částí, a tedy že pravděpodobnost sjednocení disjunktních jevů je součtem jejich pravděpodobností:
            {`$$
                P(\\langle a, c \\rangle) = P(\\langle a, b \\rangle) + P(\\langle b, c \\rangle).
            $$`}
            </p>
            <p>
            Vidíme, že pravděpodobnost se chová aditivně - což očekáváme, a chceme.
            Dále musí platit, že celková pravděpodobnost je 1:
            {`$$
                P((-\\infty, \\infty)) = \\int_{-\\infty}^{\\infty} f(x) \\text{d} x = 1,
            $$`}
            což znamená, že celková velikost plochy pod hustotu je jedna.
            </p>

            <p>Máme-li nějakou nezápornou funkci, pak ještě nemusí být použitelná jako hustota pravděpodobnosti.
            Např. funkce
            {`$$
                y(x) = \\frac{1}{1+x^2}
            $$`}
            má integrál
            {`$$
                \\int_{-\\infty}^{\\infty} \\frac{1}{1+x^2} \\text{d} x = \\left[\\arctan x\\right]_{-\\infty}^{\\infty} = \\pi \\neq 1.
            $$`}
            Pokud ji ale touto hodnotou znormujeme:
            {`$$
                f(x) = \\frac{1}{\\pi} y(x) = \\frac{1}{\\pi} \\frac{1}{1+x^2},
            $$`}
            potom
            {`$$
                \\int_{-\\infty}^{\\infty} \\frac{1}{\\pi} y(x) \\text{d} x = \\frac{1}{\\pi} \\pi = 1,
            $$`}
            a už je to hustota; a je to hustota tzv. Cauchyova rozdělení, které je speciálním případem
            Studentova t-rozdělení (pro {"$\\nu=1$"}).
            </p>

            <p><i>Poznámka: V sekci o jevovém poli jsme požadovali, aby
            pravděpodobnost {"$P(A)$"} byla definovaná na každém prvku jevového pole.
            V {"$\\mathbb{R}$"} to
            jsou borelovské množiny (aniž jsme si je definovali; jen si řekneme, že mj. obsahují
            všechny intervaly, všechna sjednocení intervalů, a konečné a spočetně množiny bodů).
            Vzorec s integrálem {"$P(\\langle a, b \\rangle) = \\int_a^b f(x) \\text{d} x$"} ale
            evidentně definuje pravděpodobnost jen pro intervaly, a nikoli pro všechny
            borelovské množiny. Existuje však matematická věta, která říká, že množina
            intervalů je takzvaný generátor borel. množin, a že stačí definovat pravděpodobnost
            jen na generátoru - je tím pak jednoznačně dodefinovatelná všude.
            </i></p>

            <h2 id="density-observations">Pozorování</h2>

            <p>Pravděpodobnost jednobodové množiny je nulová:
            {`$$
                P(\\{a\\}) = \\int_a^a f(x) \\text{d} x = [F(x)]_{a}^{a} = 0.
            $$`}
            </p>

            <p>Pravděpodobnost konečné množiny (n bodů) je taky nulová,
            protože je součtem pravděpodobností jednotlivých bodů, což jsou nuly.
            Analogicky pro spočetnou množinu.
            </p>

            <p>Protože každý jeden bod má pravděpodobnost nula, platí
            {`$$
                P(\\langle a, b \\rangle) = P(\\langle a, b )) = P(( a, b \\rangle) = P((a,b)),
            $$`}
            tedy interval má stejnou pravděpodobnost ať už je otevřený či uzavřený.
            </p>

            <p>Je názorné, že v oblastech/intervalech, kde je hustota vyšší, vychází také
            vyšší pravděpodobnost.
            </p>

            <p>Často se počítá plocha/pravděpodobnost zleva až po dané x:
            {`$$
                F(x) = P((-\\infty, x)) = \\int_{-\\infty}^{x} f(t) \\text{d} t,
            $$`}
            Pokud si hodnotu integrálu zapamatujeme a přiřadíme danému x, a takto to uděláme
            pro všechna x, dostaneme distribuční funkci {"$F(x)$"}.
            </p>

            <h2 id="density-uniform">Rovnoměrná hustota</h2>

            <p>Pro rovnoměrnou hustotu na intervalu {"$\\langle a, b \\rangle$"} je
            {`$$
                f(x) =
                \\begin{cases}
                                 0 & \\text{ pro } x < a, \\\\
                    \\frac{1}{b-a} & \\text{ pro } x \\in \\langle a, b \\rangle, \\\\
                                 0 & \\text{ pro } x > b.
                \\end{cases}
            $$`}
            Zkontrolujeme integrál:
            {`$$\\begin{align*}
                \\int_{-\\infty}^{\\infty} f(x) \\text{d} x & = \\int_{-\\infty}^{a} 0 \\text{d} x + \\int_{a}^{b} \\frac{1}{b-a} \\text{d} x + \\int_{b}^{\\infty} 0 \\text{d} x \\\\
                                                         & = 0 + \\frac{1}{b-a} [x]_{a}^{b} + 0 = \\frac{1}{b-a} [b-a] = 1.
            \\end{align*}$$`}
            Máme-li pod-interval {"$\\langle c, d \\rangle$"}, kde {"$a \\leq c \\leq d \\leq b$"}, pak
            {`$$
                P(\\langle c, d \\rangle) = \\int_c^d f(x) \\text{d} x = \\frac{1}{b-a} [d-c] = \\frac{d-c}{b-a}.
            $$`}
            Délka {"$L$"} intervalu {"$\\langle a, b \\rangle$"} je {"$L = b-a$"}, délka {"$l$"} intervalu {"$\\langle c, d \\rangle$"} je {"$l = d-c$"},
            a pravděpodobnost je tedy poměrem těchto délek:
            {`$$
                P(\\langle c, d \\rangle) = \\frac{l}{L}.
            $$`}
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density_unif} style={{width: "95%"}}/>
            </div>

            <p>Zároveň je vidět, že velikost plochy je:
            {`$$
                    \\text{základna} \\times \\text{výška} = (d - c) \\times \\frac{1}{b-a} = \\frac{d-c}{b-a}.
            $$`}
            Můžeme říct, že pravděpodobnost intervalu je přímo úměrná jeho délce {"$l$"}, s koeficientem {"$\\frac{1}{b-a}$"}:
            {`$$
                P(\\langle c, d \\rangle) = \\frac{l}{b-a}
            $$`}
            (toto platí pro intervaly, které jsou podmnožinami intervalu {"$\\langle a, b \\rangle$"}).
            Dvakrát delší interval má dvakrát větší pravděpodobnost, třikrát delší interval třikrát větší pravděpodobnost, atd.
            </p>

            <h2 id="density-linear">Lineární hustota</h2>

            <p>Pro lineární hustotu na intervalu {"$\\langle a, b \\rangle$"} je
            {`$$
                f(x) =
                \\begin{cases}
                                 0 & \\text{ pro } x < a, \\\\
                    \\frac{2}{(b-a)^2} (x-a) & \\text{ pro } x \\in \\langle a, b \\rangle, \\\\
                                 0 & \\text{ pro } x > b.
                \\end{cases}
            $$`}
            Zkontrolujeme integrál:
            {`$$\\begin{align*}
                \\int_{-\\infty}^{\\infty} f(x) \\text{d} x & = \\int_{-\\infty}^{a} 0 \\text{d} x + \\int_{a}^{b} \\frac{2}{(b-a)^2} (x-a) \\text{d} x + \\int_{b}^{\\infty} 0 \\text{d} x \\\\
                                                         & = 0 + \\frac{2}{(b-a)^2} \\left[\\frac{1}{2} (x-a)^2\\right]_{a}^{b} + 0 = \\frac{2}{(b-a)^2} \\left[\\frac{1}{2} (b-a)^2\\right] = 1.
            \\end{align*}$$`}
            Máme-li pod-interval {"$\\langle c, d \\rangle$"}, kde {"$a \\leq c \\leq d \\leq b$"}, pak
            {`$$
                P(\\langle c, d \\rangle) = \\int_c^d f(x) \\text{d} x = \\frac{2}{(b-a)^2} \\left[\\frac{1}{2} (d-a)^2 - \\frac{1}{2} (c-a)^2\\right] = \\frac{(d-c)(d+c-2a)}{(b-a)^2}.
            $$`}
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density_linear} style={{width: "95%"}}/>
            </div>

            <p>Je vidět, že interval, který je více vpravo, bude mít větší pravděpodobnost než stejně dlouhý interval, který je více vlevo,
            protože hustota více vpravo je vyšší.
            </p>
            <p>Směrnice přímky nad intervalem {"$\\langle a, b \\rangle$"} je
            {`$$
                \\frac{\\Delta y}{\\Delta x} = \\frac{\\frac{2}{b-a}}{b-a} = \\frac{2}{(b-a)^2},
            $$`}
            obsah modré plochy lze ověřit jako součet plochy obdélníku a trojúhelníku.
            </p>

            <p>Všimněme si, že když porovnáme stejnoměrnou a lineární hustotu na stejném
            intervalu, vypadá to takto:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density_comparison} style={{width: "50%"}}/>
            </div>

            <p>.... hustota se musí normovat tak, aby plocha pod ní byla 1.
            Aby trojúhelník měl stejnou plochu jako obdélník (tj. 1), jeho výška musí být dvojnásobná.
            </p>

            <h2 id="density-analogy">Analogie s hustotou hmotnosti</h2>

            <p>Hustota pravděpodobnosti je totéž co <strong>lineární hustota hmotnosti</strong>,
            pokud by celková hmotnost objektu byla 1. Lineární hustota hmotnosti má
            jednotku {"$kg/m$"}.*
            Představte si, že
            trojúhelníkový profil vystřihnete z lepenky a postavíte tak, jak je na obrázku.
            (Přičemž celková hmotnost lepenkového profilu bude 1 kg).
            Objekt bude mít lineární hustotu hmotnosti přesně jako v tomto příkladu. Pokud
            byste z něj vyřízli svislý pásek a zvážili ho, jeho hmotnost bude dána
            integrací lineární hustoty přes podstavu tohoto pásku.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={density_explanation} style={{width: "50%"}}/>
            </div>

            <p>Alternativně si můžeme představit, že máme tyč, která je uvnitř
            vyrobena tak, že se její hustota lineárně zvyšuje (například její výplň
            je hustší a hustší, jak jdeme doprava).
            </p>

            <p>
            Dejme tomu, lepenkový profil nebo tyč "rozemeleme" na prášek/na molekuly,
            a pak s hromádky náhodně vybereme jednu molekulu. Jaká je pravděpodobnost, že tato molekula
            pochází z intervalu {"$\\langle c, d \\rangle$"}? Je to právě hmotnost
            tohoto intervalu (obecně hmotnost tohoto intervalu k poměru k celkové hmotnosti,
            která je zde ale 1kg). Na obrázku modré tečky reprezentují jednotlivé molekuly.
            </p>
            <ul>
                <li><p>Pravděpodobnost intervalu {"$\\langle c, d \\rangle$"} je
                pravděpodobnost výběru molekuly z tohoto intervalu,
                a je to integrál hustoty (pravděpodobnosti) přes tento interval.
                </p></li>
                <li><p>Hmotnost intervalu {"$\\langle c, d \\rangle$"} je
                integrál hustoty (hmotnosti) přes tento interval.
                </p></li>
            </ul>
            <p>
            Pokud je celková hmotnost {"$1 \\ kg$"}, pak hustota hmotnosti je totožná
            s hustotou pravděpodobnosti.
            Pokud je celková hmotnost {"$M \\ kg$"}, pak hustota pravděpodobnosti
            je hustota hmotnosti vydělená {"$M$"} (normování pravděpodobnosti na 1).
            </p>


            <p>*Pokud vám jednotka přijde divná, zde vysvětlení:</p>
            <ul>
                <li>
                    <p>Hustota hmotnosti na jednotku objemu: {"$kg/m^3$"}. Ne tuto jsme zvyklí. Objemová hustota.</p>
                </li>
                <li>
                    <p>Hustota hmotnosti na jednotku plochy: {"$kg/m^2$"}. Plošná hustota.</p>
                </li>
                <li>
                    <p>Hustota hmotnosti na jednotku délky: {"$kg/m$"}. Lineární hustota.</p>
                </li>
            </ul>

            <p>Pozn. Pojem "lineární hustota" zde používáme ve dvou různých významech -
            1. hustota na přímce (kilogramy na metr) /metr na prvou = 1D/,
            2. hustota, jejímž grafem je přímka (lineární funkce).
            Tyto dva koncepty se nám tu zrovna potkaly. Snad to není matoucí.
            </p>

            <h2 id="density-general">Hustota obecně</h2>

            <p>Hustota samozřejmě nemusí být stejnoměrná nebo lineární,
            ale daná např. jako normální, exponenciální, nebo definovaná po částech (piecewise).
            </p>
            <p>
            Pokud je definovaná po částech, v integrálech musíme integraci rozdělit v bodech
            kde se mění předpis (vzorec) {"$f(x)$"}.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

