import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';


export const EventsMeta = {
    title: "Jevy",
    shortTitle: "Jevy",
    path: Path.events,
    element: (sectionNumber:string) => <Events sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "ev-summary",
];

export function Events({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={EventsMeta.title} TOCSpec={TOCSpec}>

            <p><i>Připravujeme...</i></p>

            <h2 id="ev-summary">Shrnutí</h2>

            <p>
            <strong>Jevy</strong> (nebo také <strong>náhodné jevy</strong>) jsou podmnožiny {"$\\Omega$"}.
            Každý sestává z nějakých elementárních jevů - je to množina vybraných elementárních jevů - prvků z {"$\\Omega$"}.
            </p>

            <p>
            O jevu řekneme že <strong>nastane</strong> (<strong>nastoupí</strong>), když nastane elementární
            jev, který je v jevu obsažen. Např. jev "na kostce padlo sudé číslo" je jev
            {`$$
                A = \\{2, 4, 6\\} \\subset \\Omega = \\{1, 2, 3, 4, 5, 6\\}.
            $$`}
            Výsledek pokusu (hodu kostkou) je vždy právě jeden <i>elementární</i> jev {"$\\omega \\in \\Omega$"}.
            Když nastane elementární jev {"$\\omega = 2$"}, nastane i jev {"$A$"} ("padlo sudé číslo").
            Když nastane elementární jev {"$\\omega = 4$"}, jev {"$A$"} ("padlo sudé číslo") nastane také.
            Totéž pro {"$\\omega = 6$"}.
            Pokud nastane elementární jev {"$\\omega = 1$"}, jev {"$A$"} nenastane (nenastalo, že by padlo sudé čislo).
            </p>

            <p>
            Jeden konkrétní elementární jev (např. {"$\\omega = 2$"}) je typicky prvkem mnoha různých jevů:
            </p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Slovní popis jevu</th>
                        <th>Jev</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Padlo sudé číslo</td>
                        <td>{"$A = \\{2, 4, 6\\}$"}</td>
                    </tr>
                    <tr>
                        <td>Padlo číslo menší než 4</td>
                        <td>{"$B = \\{1, 2, 3\\}$"}</td>
                    </tr>
                    <tr>
                        <td>Padlo prvočíslo</td>
                        <td>{"$C = \\{2, 3, 5\\}$"}</td>
                    </tr>
                    <tr>
                        <td>Padlo číslo větší než 1</td>
                        <td>{"$D = \\{2, 3, 4, 5, 6\\}$"}</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Když nastane elementární jev, nastanou zároveň všechny jevy, jichž je prvkem (které ho obsahují).
            Když např. nastane elementární jev {"$\\omega = 2$"}, nastanou zároveň
            jevy {"$A$"}, {"$B$"}, {"$C$"}, {"$D$"}, ... z tabulky výše. Slovně: Když padlo číslo 2, nastalo to,
            že padlo sudé číslo (A), zároveň nastalo i to, že padlo číslo menší než 4 (B), že padlo prvočíslo (C),
            že padlo číslo větší než 1 (D), atd.
            </p>

            <p>
            Jevy jsou prostě množiny (podmnožiny {"$\\Omega$"}), a tak pro ně platí všechny běžné vlastnosti
            množin (které známe, takže se tu vlastně neděje nic nového). Tedy jediné, co nové je,
            tak je <strong>nová specifická terminologie</strong> / interpretace:
            </p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Zápis</th>
                        <th>Množinový název</th>
                        <th>Jevový název</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$\\emptyset$"}</td>
                        <td>Prázdná množina</td>
                        <td><strong>Nemožný jev</strong></td>
                    </tr>
                    <tr>
                        <td>{"$\\Omega$"}</td>
                        <td>Univerzum</td>
                        <td><strong>Jistý jev</strong></td>
                    </tr>
                    <tr>
                        <td>{"$A^\\complement$"}</td>
                        <td>Komplement (doplněk) /množiny {"$A$"}/</td>
                        <td><strong>Opačný jev</strong> /k jevu {"$A$"}/</td>
                    </tr>
                    <tr>
                        <td>{"$A\\cap B = \\emptyset$"}</td>
                        <td>Množiny {"$A, B$"} jsou disjunktní</td>
                        <td>Jevy {"$A, B$"} jsou <strong>neslučitelné</strong></td>
                    </tr>
                </tbody>
            </table>

            <p>
            Jevy jsou entity, kterým budeme chtít přiřadit pravděpodobnost.
            Apriori tedy nepřiřazujeme pravděpodobnost elementárním jevům
            (někdy ano, ale není to princip, který by se dal použít univerzálně), ale právě jevům.
            </p>

            <p>Jaká je množina všech jevů? Je to množina všech podmnožin {"$\\Omega$"},
            tedy potenční množina {"$\\mathcal{P}(\\Omega$"}.
            </p>

            <p>
            Když se pokoušíme jevům definovat pravděpodobnost, tak v některý situacích
            (například u spojité pravděpodobnosti na reálných číslech) narazíme na překvapivé
            technické (matematické) problémy - a ukáže se, že někdy v principu není možné konzistentně
            přiřadit pravděpodobnost všem prvkům potenční množiny {"$\\mathcal{P}(\\Omega)$"}.
            <i>Co to přesně znamená si možná ukážeme později.</i>
            </p>

            <p>
            Tento problém se vyřeší tak, že se některé "zlobivé" množiny vyřadí.
            Pravděpodobnost pak není definovaná na celé potenční množině {"$\\mathcal{P}(\\Omega)$"},
            ale pouze na nějakém jejím subsystému (podmnožině).
            Tento subsystém ale nevolíme ledajaký - chceme aby, splňoval nějaké hezké praktické požadavky
            - např. abychom z něho "nevyskočili" při provádění běžných množinových operací
            (aby byl takzvaně uzavřený na sjednocení, průnik, doplněk, ...). Tj. když z něj vezmeme libovolné
            dvě množiny, a uděláme jejich průnik, získaná množina by měla být také prvkem našeho subsystému.
            </p>

            <p>
            Tomuto subsystému se říká <strong>jevové pole</strong>, a značí se často {"$\\mathcal{F}$"} nebo
            {"$\\mathcal{A}$"}.
            </p>

            <p>
            Pro praktické potřeby tohoto kurzu si stačí vždy jako jevové pole představit celou
            množinu {"$\\mathcal{P}(\\Omega)$"} všech podmnožin. Je to nekorektnost, která slouží jako dočasný
            pomocný stupínek na cestě k pochopení, a kterou si můžete odstranit/korigovat později,
            pokud k tomu budete mít čas, chuť a energii.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

