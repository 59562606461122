import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { ELink } from '~/ExternalLink';


export const MeanKindsMeta = {
    title: "Druhy průměrů",
    shortTitle: "Druhy průměrů",
    path: Path.mean_kinds,
    element: (sectionNumber:string) => <Events sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const TOCSpec = [
    "meankinds-arithmetic",
    "meankinds-geometric",
    "meankinds-quadratic",
    "meankinds-harmonic",
    "meankinds-properties",
];


export function Events({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={MeanKindsMeta.title} TOCSpec={TOCSpec}>

            <table className="simple-table" id='table-kinds-of-mean'>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Druh průměru</th>
                        <th>Vzorec</th>
                        <th>Pozn.</th>
                        <th>Def. obor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>K</td>
                        <td>Kvadratický</td>
                        <td>{"$\\overline{x}_K = \\sqrt{\\frac{1}{n}\\sum_{i=1}^n x_i^2}$"}</td>
                        <td>Odmocnina průměru druhých mocnin.</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>A</td>
                        <td>Aritmetický</td>
                        <td>{"$\\overline{x}_A = \\frac{1}{n}\\sum_{i=1}^n x_i$"}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>G</td>
                        <td>Geometrický</td>
                        <td>{"$\\overline{x}_G = \\sqrt[n]{\\prod_{i=1}^n x_i}$"}</td>
                        <td>N-tá odmocnina součinu n hodnot.</td>
                        <td>{"$x_i \\geq 0$"}</td>
                    </tr>
                    <tr>
                        <td>H</td>
                        <td>Harmonický</td>
                        <td>{"$\\overline{x}_H = \\frac{n}{\\sum_{i=1}^n \\frac{1}{x_i}}$"}</td>
                        <td>Převrácená hodnota průměru převrácených hodnot.</td>
                        <td>{"$x_i > 0$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Pamatujeme si 'KAGH':
            {"$$\\overline{x}_K \\geq \\overline{x}_A \\geq \\overline{x}_G \\geq \\overline{x}_H$$"}
            </p>

            <h2 id="meankinds-arithmetic">Aritmetický průměr</h2>

            <p>
            {
                "$$\\overline{x}_A = \\frac{1}{n}\\sum_{i=1}^n x_i$$"
            }

            Fyzikálně se jedná o <strong>těžiště</strong> bodů, které reprezentují jednotlivé hodnoty {"$x_i$"}.
            Představme si, že máme lineární stupnici (pravítko), na kterou položíme závaží o stejné hmotnosti {"$m$"} na každé souřadnici {"$x_i$"}.
            Pravítko podepřeme v bodě {"$z$"}, který najdeme tak, aby pravítko bylo vyvážené.
            Zde musí platit rovnováha na páce - tj. součet momentů všech sil musí být nulový:
            {
                "$$\\sum_{i=1}^n m(x_i - z) = 0$$"
            }
            Odtud dostáváme:
            {
                "$$\\sum_{i=1}^n m(x_i - z) = 0 \\quad \\rightarrow \\quad \\sum_{i=1}^n x_i - nz = 0 \\quad \\rightarrow \\quad z = \\frac{1}{n} \\sum_{i=1}^n x_i = \\overline{x}_A$$"
            }

            Aritmetický průměr také získáme jako řešení minimalizačního problému, kde minimalizujeme součet druhých mocnin odchylek od průměru:
            {
                "$$\\overline{x}_A = \\arg\\min_z \\sum_{i=1}^n (x_i - z)^2$$"
            }
            V tomto zápisu je jediná proměnná {"$z$"} a vše ostatní jsou konstanty. Minimum hledáme tak, že derivujeme podle {"$z$"} a derivaci položíme rovnou nule:
            {
                "$$\\frac{d}{dz} \\sum_{i=1}^n (x_i - z)^2 = - \\sum_{i=1}^n 2 (x_i - z) = 0 \\quad \\rightarrow \\quad \\ldots $$"
            }
            </p>

            <h2 id="meankinds-geometric">Geometrický průměr</h2>
            <p>
            POZOR: Má smysl pouze pro <span style={{color: 'red'}}>nezáporné</span> hodnoty.
            </p>
            <p>
            {
                "$$\\overline{x}_G = \\sqrt[n]{\\prod_{i=1}^n x_i} = (x_1x_2\\ldots x_n)^{\\frac{1}{n}}$$"
            }
            Geometrický průměr přirozeně získáme při výpočtu průměrného úroku:
            Máme nějaký počáteční vklad {"$C$"}, který vložíme do banky, a banka jednou za rok (na konci roku) na účet připíše úrok.
            Úrok je v každém roce jiný - v i-tém roce má výši {"$u_i$"}. Na konci prvního roku máme na účtu částku {"$C r_1$"}, kde {"$r_1 = 1 + u_1$"}.
            Je-li např. v prvním roce úrok 5%, pak je {"$u_1 = 0.05$"} a {"$r_1 = 1.05$"}.
            </p>

            <div style={{display: 'flex', width: '100%', justifyContent: "center"}}>
            <table className='simple-table' id='table-interest-rates'>
                <thead>
                    <tr>
                        <th>Rok</th>
                        <th>Částka na konci roku</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>{"$Cr_1$"}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>{"$Cr_1r_2$"}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>{"$Cr_1r_2r_3$"}</td>
                    </tr>
                    <tr>
                        <td>{"$\\cdots$"}</td>
                        <td>{"$\\cdots$"}</td>
                    </tr>
                    <tr>
                        <td>n</td>
                        <td>{"$Cr_1r_2r_3\\cdots r_n$"}</td>
                    </tr>
                </tbody>
            </table>
            </div>

            <p>
            Po n letech máme na účtu částku {"$C r_1r_2r_3\\cdots r_n$"}.
            Představa 'průměrného úroku' je taková, že bychom měli celých n let pořád stejný úrok {"$r$"},
            přičemž bychom na konci dosáhli stejnou částku:
            {
                "$$Cr^n = Cr_1r_2r_3\\cdots r_n$$"
            }
            Odtud dostáváme:
            {
                "$$r^n = r_1r_2r_3\\cdots r_n \\quad \\rightarrow \\quad r = (r_1r_2r_3\\cdots r_n)^{\\frac{1}{n}}$$"
            }
            </p>

            <h2 id="meankinds-quadratic">Kvadratický průměr</h2>
            <p>
            {
                "$$\\overline{x}_K = \\sqrt{\\frac{1}{n}\\sum_{i=1}^n x_i^2}$$"
            }
            Mějme {"$n$"} stejných molekul v plynu, které se pohybují různými rychlostmi. Energie jedné molekuly je{"$E_i = \\frac{1}{2}mv_i^2$"}.
            Celková energie plynu je součtem energií všech molekul: {"$E = \\sum_{i=1}^n E_i = \\sum_{i=1}^n \\frac{1}{2}mv_i^2$"}.
            Zajímá nás 'průměrná rychlost' molekul v tomto smyslu: Kdyby se všechny molekuly pohybovaly stejnou rychlostí {"$v$"},
            přičemž by celková energie plynu byla stejná, jaká by to byla rychlost?
            {
                "$$n \\cdot \\frac{1}{2} m v^2 = \\sum_{i=1}^n \\frac{1}{2}mv_i^2 \\quad \\rightarrow \\quad v^2 = \\frac{1}{n} \\sum_{i=1}^n v_i^2 \\quad \\rightarrow \\quad v = \\sqrt{\\frac{1}{n} \\sum_{i=1}^n v_i^2}$$"
            }
            Této rychlosti říkáme střední kvadratická rychlost (viz <ELink href="https://cs.wikipedia.org/wiki/St%C5%99edn%C3%AD_kvadratick%C3%A1_rychlost">Wikipedie</ELink>).
            </p>

            <h2 id="meankinds-harmonic">Harmonický průměr</h2>
            <p>
            POZOR: Má smysl pouze pro <span style={{color: 'red'}}>kladné</span> hodnoty.
            <i> (S trochou kreativity lze pozorovat, že bude fungovat i když budou všechny hodnoty záporné. Pro hodnoty se smíšenými znaménky ale výsledek nemá dobře definovaný smysl.)</i>
            </p>
            <p>
            {
                "$$\\overline{x}_H = \\frac{n}{\\sum_{i=1}^n \\frac{1}{x_i}} = \\frac{1}{\\frac{\\frac{1}{x_1} + \\frac{1}{x_2} + \\cdots + \\frac{1}{x_n}}{n}}$$"
            }
            Slovně: Uděláme aritmetický průměr z převrácených hodnot, a výsledek zase zpátky převrátíme.
            </p>

            <p>
            Uvažujme pohybující se objekt, který urazí {"$n$"} stejně dlouhých úseků dráhy (délku jednoho úseku označme {"$s$"}), přičemž na úseku {"$i$"} má rychlost {"$v_i$"}.
            Jaká je průměrná rychlost objektu?
            {
                "$$\\overline{v} = \\frac{\\mathrm{celková \\ dráha}}{\\mathrm{celkový \\ čas}} = \\frac{ns}{t_1 + t_2 + \\cdots + t_n} = \\frac{ns}{\\frac{s}{v_1} + \\frac{s}{v_2} + \\cdots + \\frac{s}{v_n}} = \\frac{n}{\\frac{1}{v_1} + \\frac{1}{v_2} + \\cdots + \\frac{1}{v_n}}$$"
            }
            Jedná se o harmonický průměr rychlostí.
            </p>

            <h2 id="meankinds-properties">Vlastnosti průměrů</h2>
            <p>
            A: Jsou-li všechny hodnoty stejné: {"$x_1 = x_2 = \\cdots x_n = x$"}, pak jsou všechny průměry rovny této hodnotě:
            {`$$\\begin{align*}
                \\overline{x}_K & = \\sqrt{\\frac{1}{n} n x^2} = \\sqrt{x^2} = |x| \\mathrm{\\textit{(eventuálně až na znaménko)}}\\\\
                \\overline{x}_A & = \\frac{1}{n} (x + x + \\cdots + x) = \\frac{1}{n} n x = x \\\\
                \\overline{x}_G & = (x\\cdot x\\cdot x \\cdots \\cdot x)^{\\frac{1}{n}} = (x^n)^{\\frac{1}{n}} = x \\\\
                \\overline{x}_H & = \\frac{n}{\\frac{1}{x} + \\frac{1}{x} + \\cdots + \\frac{1}{x}} = \\frac{n}{n\\frac{1}{x}} = x \\\\
            \\end{align*}$$`}
            </p>

            <p>
            B: Označme {"$x_{(1)} = \\min x_i$"} a {"$x_{(n)} = \\max x_i$"}. Každý z těchto průměrů vyjde vždy mezi minimem a maximem:
            {
                "$$x_{(1)} \\leq \\overline{x}_{K, A, G, H} \\leq x_{(n)}$$"
            }
            </p>

            <p>
            C: Mějme data {"$x_i$"}, a vytvořme na jejich základě odvozená (transformovaná) data {"$y_i = k x_i$"} (každou hodnotu vynásobíme faktorem {"$k$"}). Potom pro všechny průměry platí:
            {
                "$$\\overline{y}_{K,A,G,H} = k \\overline{x}_{K,A,G,H}$$"
            }
            </p>
            Pro kvadratický průměr přesněji: {"$\\overline{y}_K = |k| \\overline{x}_K$"}. U geometrického průměru samozřejmě lze použít jen {"$k \\geq 0$"}, a u harmonického jen {"$k \\neq 0$"}.

            <p>
            D: Mějme data {"$x_i$"}, a vytvořme na jejich základě odvozená (transformovaná) data {"$y_i = x_i + c$"} (ke každé hodnotě přičteme konstantu {"$c$"}).
            Potom pro aritmetický průměr (ale nikoli pro ostatní průměry platí):
            {
                "$$\\overline{y}_{A} = \\overline{x}_{A} + c$$"
            }
            Aritmetický průměr obecně splňuje {"$\\overline{kx + c}_{A} = k \\overline{x}_{A} + c$"}, chová se tedy lineárně. Není divu - je to lineární funkce
            na vektorech velikosti {"$n$"}: {"$\\overline{x}_A = f(x_1, x_2, \\ldots, x_n) = \\frac{1}{n}x_1 + \\frac{1}{n}x_2 + \\cdots + \\frac{1}{n}x_n$"} (viz lineární algebra).
            Ostatní průměry nejsou lineární funkce.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

