import React from 'react';

import { Path } from '~/paths';
import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';

const Ovce = require('../assets/images/ovce.png');
const BodyVRovineCount = require('../assets/images/body_v_rovine_count.png');
const CountUncount = require('../assets/images/count_uncount.png');


export const CountabilityMeta = {
    title: "Spočetnost",
    shortTitle: "Spočetnost",
    path: Path.countability,
    element: () => <Countability />,
    sectionNumber: "",
}

const TOCSpec = [
    "countability-infty",
];

export function Countability() {

    return (
        <ChapterWrapper sectionNumber={CountabilityMeta.sectionNumber} title={CountabilityMeta.title} TOCSpec={TOCSpec}>

            <p>
            Spočetné součty {"$\\sum_{i=0}^{\\infty}$"} a spočetná sjednocení {"$\\bigcup_{i=0}^{\\infty}$"} se
            prolínají teorií pravděpodobnosti a jsou jejími základními nástroji/stavebními kameny.
            Jestli ještě pojem spočetnost neznáte, a pokud vás zajímá, jak je to s různě velkými nekonečny,
            tato kapitola je pro vás.
            </p>

            <h2 id="countability-infty">Nekonečno a spočetnost</h2>

            <p>Množiny se podle počtu prvků dělí na:
            {/* <ul>
                <li>konečné</li>
                <li>nekonečné
                    <ul>
                        <li>spočetné (spočetně nekonečné)</li>
                        <li>nespočetné (nespočetně nekonečné)</li>
                    </ul>
                </li>
            </ul> */}
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={CountUncount} style={{maxWidth: 350}} alt={"Dělení množin dle (ne)konečnosti a (ne)spočetnosti."} />
            </div>
            <p>
            Konečná množina, např. {"$A = \\{-1, 0, 1\\}$"}, má konečný počet prvků - tj. její velikost (aneb mohutnost, kardinalita; značí se {"$|A|$"}) je nějaké číslo z {"$\\mathbb{N}$"} (zde {"$|A| = 3 \\in \\mathbb{N}$"}).
            </p>

            <p>A jak je to s nekonečnými množinami?
            Pastevci, kteří kdysi uměli počítat maximálně do dvaceti<sup>1</sup>, stejně dokázali porovnat velikosti svých stád ovcí, i když ve stádech bylo více než dvacet ovcí. Jak to udělali?
            Každý seřadil své ovce do řady za sebou, pak postavili řady k sobě, a nechali ovce odcházet po párech (viz obrázek níže, kde stádo A je větší než stádo B). Ten, komu na konci v řadě zbyly ovce, měl větší stádo.
            Pokud poslední pár ovcí odešel a nezbyla další ovce ani jednomu, pak jejich stáda byla stejně velká.
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={Ovce} style={{maxWidth: 250}} alt={"Ovce odcházející po párech."} />
            </div>
            <p>
            Podívejme se na případ, kdy stáda byla stejně velká. Potom mezi stády existovala <strong>bijekce</strong> - vzájemně jednoznačné zobrazení. Každé ovci z prvního stáda byla přiřazena právě jedna ovce z druhého stáda,
            a každé ovci z druhého stáda byla přiřazena právě jedna ovce z prvního stáda. Pokud mezi množinami (stády) existuje bijekce (takovéto přiřazení), potom mají stejnou velikost.
            </p>
            <p>
            Ilustrujme si toto na příkladu přirozených čísel a bodů v rovině s celočíselnými souřadnicemi. Na první pohled by se mohlo zdát, že bodů v rovině je větší množství, ale na obrázku vidíme, že je lze jednoznačně očíslovat
            přirozenými čísly - tedy že existuje bijekce mezi nimi a {"$\\mathbb{N}$"}
            </p>
            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={BodyVRovineCount} style={{maxWidth: 550}} alt={"Očíslování bodů v rovině."} />
            </div>
            <p>
            Množina {"$\\mathbb{N}$"} se nazývá <strong>spočetně nekonečná</strong>, a každá množina, která má stejnou velikost jako {"$\\mathbb{N}$"}
            &nbsp;(existuje mezi nimi bijekce, aneb množinu lze jednoznačně očíslovat), se taky nazývá spočetně nekonečná.
            Velikost (kardinalita) množiny {"$\\mathbb{N}$"} se označuje {"$\\aleph_0$"} (čtěte "alef nula").
            Protože tato velikost není žádné konečné číslo, potřebujeme pro ni nějaký nový symbol - a volba historicky padla právě na  {"$\\aleph_0$"}.<sup>2</sup>
            </p>
            <p>Termín <strong>spočetná</strong> se potom používá pro množiny, které jsou buď konečné, anebo spočetně nekonečné.
            Tedy jejich kardinalita je {"$\\aleph_0$"} nebo menší.
            </p>
            <p>
            Ukazuje se, že množinu reálných čísel {"$\\left(\\mathbb{R}\\right)$"} nelze žádným způsobem očíslovat přirozenými čísly
            (důkaz je rychlý a názorný - takzvaná <ELink href={"https://en.wikipedia.org/wiki/Cantor%27s_diagonal_argument"}>{"Cantorova diagonální metoda"}</ELink>).
            Ať číslujeme jak číslujeme, množinu reálných čísel nikdy celou nemůžeme pokrýt.
            Množina {"$\\mathbb{R}$"} je tedy striktně větší než množina {"$\\mathbb{N}$"}.
            Je to "větší stádo ovcí." Říkáme, že {"$\\mathbb{R}$"} je množina <strong>nespočetná</strong>.
            Nekonečná množina, která je větší než {"$\\mathbb{N}$"} (nelze ji očíslovat), se nazývá nespočetná. Její kardinalita je větší než {"$\\aleph_0$"}.
            </p>
            <p>
            Zhruba řečeno, máme dvě různě velká nekonečna - spočetné (menší) a nespočetné (větší).<sup>3</sup>
            </p>
            <p>
            Lze dokázat, že racionálních čísel {"$\\left(\\mathbb{Q}\\right)$"} je také jen spočetně mnoho.
            </p>

            <p>
            Pokud Vás to zaujalo, můžete svoji představivost otestovat na příkladu slavného <strong>Hilbertova hotelu</strong>:
            </p>
            <ul>
                <li>
                    <ELink href={"https://refresher.cz/34170-Hilbertuv-paradox-o-nekonecnem-hotelu-aneb-vsechna-nekonecna-nejsou-stejna"}>{"Odkaz 1 - refresher."}</ELink>
                </li>
                <li>
                    <ELink href={"https://prase.cz/archive/35/uvod1s.pdf"}>{"Odkaz 2 - prase."}</ELink>
                </li>
            </ul>


            <p>
                <sup>1</sup>Tento příklad možná není ani tak historický, jako spíše fiktivní. Nicméně nepravidelné tvary číslovek do 20 v různých evropských jazycích naznačují,
                že někteří naši předkové možná skutečně počítali do 20, nebo používali soustavu o základu 20. Viz např.
                <ELink href={"https://aiaioo.wordpress.com/2014/05/03/languages-and-numbers/"}> zde</ELink>.
            </p>
            <p>
                <sup>2</sup>Takzvaná ordinální a kardinální čísla jsou vysvětlena např. na&nbsp;
                <ELink href={"https://cs.wikipedia.org/wiki/Kardin%C3%A1ln%C3%AD_%C4%8D%C3%ADslo"}>{"wikipedii."}</ELink>
            </p>
            <p>
                <sup>3</sup>Nad rámec tohoto úvodu je nutno poznamenat, že nespočetných nekonečen odstupňovaných dle velikosti je nekonečně mnoho.
                        Např. {"$\\mathbb{R}^\\mathbb{R}$"} je větší než {"$\\mathbb{R}$"}, {"$\\mathbb{R}^{\\mathbb{R}^\\mathbb{R}}$"} je větší než {"$\\mathbb{R}^\\mathbb{R}$"}, atd.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

