import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';

export const InferenceIntroMeta = {
    title: "Inference",
    shortTitle: "Inference",
    path: Path.inference_intro,
    element: (sectionNumber:string) => <InferenceIntro sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function InferenceIntro({sectionNumber}: {sectionNumber: string}) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={InferenceIntroMeta.title}>

            <p>
            Inference pojednává mj. o tom, jak korektně odhadovat informace o populaci na základě náhodného výběru
            (z malé podmnožiny populace). A analogicky, jak korektně odhadovat informace o podkladovém
            rozdělení pravděpodobnosti při pozorování jevů, pro něž používáme makroskopický pravděpodobnostní/statistický popis
            (statistická fyzika, teorie front, ...), nebo o rozdělení náhodné chyby při měření fyzikálních veličin.
            </p>
            <Footer/>
        </ChapterWrapper>
    );
}
