import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

const mapping_1_negs = require('../assets/images/mapping_1_negs.png');
const mapping_2_bijection = require('../assets/images/mapping_2_bijection.png');
const mapping_sets_1a = require('../assets/images/mapping_sets_1a.png');
const mapping_sets_1b = require('../assets/images/mapping_sets_1b.png');
const mapping_sets_table = require('../assets/images/mapping_sets_table.png');
const mapping_sets_table_inv1 = require('../assets/images/mapping_sets_table_inv1.png');
const mapping_sets_table_inv2 = require('../assets/images/mapping_sets_table_inv2.png');

const mapping_sets_bf = require('../assets/images/mapping_sets_bf.png');
const mapping_sets_fb = require('../assets/images/mapping_sets_fb.png');

const prenos_miry = require('../assets/images/prenos_miry.png');

export const MappingMeta = {
    title: "Zobrazení",
    shortTitle: "Zobrazení",
    path: Path.mapping,
    element: (sectionNumber:string) => <Mapping sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "mapping-mapping",
    "mapping-set-mapping",
    "mapping-use-in-probability",
];

export function Mapping({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={MappingMeta.title} TOCSpec={TOCSpec}>

        <p><i>Připravujeme...</i></p>

        <p>
        Cílem této kapitoly je vysvětlit značení {"$X^{-1}(A)$"}, které se vyskytuje v
        některých definicích v pravděpodobnosti, např. ve vztahu {"$P'(A') = P\\Big(X^{-1}(A')\\Big)$"}.
        </p>

        <p>
        <i>Pozn.: Než se dostaneme k jádru kapitoly (množinové zobrazení), uvedeme si základní pojmy k (běžnému)
        zobrazení: definiční obor, cílová množina, obor hodnot, surjekce, injekce, bijekce, inverzní zobrazení.
        Je dost pravděpodobné, že tyto pojmy už dobře znáte - v tom případě můžete kapitolu "Zobrazení" přeskočit.
        </i>
        </p>

        <h2 id="mapping-mapping">Zobrazení</h2>

        <p>
        Matematickou definici <strong>zobrazení</strong> si případně můžete dohledat
        na <ELink href={"https://cs.wikipedia.org/wiki/Zobrazen%C3%AD_(matematika)" }>Wikipedii</ELink>,
        nebo v knihách,
        např. <ELink href={"https://www.math.muni.cz/~dosla/download/skript.pdf" }>v této na straně 1 a 2</ELink>.

        Je to základní pojem matematiky, a vyplatí se mít ho pořádně "osahaný".
        </p>

        <p>
        Pojem <strong>funkce</strong> se někde používá jako synonymum pojmu zobrazení,
        jinde ve striktnějším smyslu jako zobrazení do číselného oboru (buď {"$\\mathbb{R}$"} nebo {"$\\mathbb{C}$"}),
        tedy zobrazení s číselnými výsledky/hodnotami.
        Pro potřeby této knihy je pojem funkce totožný s pojmem zobrazení.
        Náhodná proměnná je funkce (zobrazení) množiny {"$\\Omega$"} elementárních jevů do {"$\\mathbb{R}$"}.
        </p>

        <p>
        Zobrazení je vždy zobrazením
        </p>
        <ul>
            <li><p>
            nějaké <strong>množiny</strong> {"$A$"} (<span style={{color: "steelblue"}}>{'\u3008'}čeho{'\u3009'}</span> ~ definiční obor )
            </p></li>
            <li><p>
            <strong>do</strong> nějaké <strong>množiny</strong> {"$B$"} (<span style={{color: "green"}}>{'\u3008'}do čeho{'\u3009'}</span> ~ cílová množina).
            </p></li>
        </ul>

        <p>
        <span style={{color: "steelblue"}}>Zobrazení {'\u3008'}čeho{'\u3009'}</span>:
        Množina {"$A$"} se nazývá <strong>definiční obor</strong> {"$D_f$"},
        a je to množina vzorů (argumentů) zobrazení.
        Pro každý prvek (vzor, argument) z definičního oboru {"$A$"} zobrazení
        dává jeden prvek (obraz, hodnotu) v {"$B$"}. Obraz prvku je hodnota,
        na kterou je prvek zobrazen; je to hodnota, kterou zobrazení přiřazuje vzoru;
        je to výsledek vyčíslení zobrazení na daném vzoru.
        Vzor se často značí {"$x$"}, obraz {"$f(x)$"}.
        </p>

        <p>
        <strong>Obor hodnot</strong> {"$H_f$"}, je množina všech možných hodnot, které zobrazení může nabývat:
        {`$$
            H_f = \\{ f(x) \\mid x \\in A \\} \\subseteq B.
        $$`}
        Obor hodnot je obecně podmnožinou cílové množiny {"$B$"}- viz dále:
        </p>

        <p>
        <span style={{color: "green"}}>Zobrazení {'\u3008'}do čeho{'\u3009'}</span>:&nbsp;
        <strong>Cílová množina</strong> není v principu totéž co obor hodnot - je to buď obor hodnot {"$(B = H_f)$"},
        nebo jeho nadmnožina {"$(B \\supset H_f)$"} -
        v tom případě obsahuje i nějaké prvky navíc - prvky, na které zobrazení nezobrazuje,
        které nejsou obrazy žádných vzorů z {"$A$"}.
        </p>

        <p>
        Můžeme si to představit na následující analogii: {"$A$"} je
        množina lidí, kteří přišli do kina.
        Cílová množina {"$B$"} je množina sedadel, které jsou v kině k dispozici.
        Zobrazení {"$f$"} je pak přiřazení sedadla každému člověku.
        Obor hodnot je množina všech sedadel, která jsou obsazena.
        Pokud jsou všechna sedadla obsazena, pak {"$H_f = B$"}.
        Pokud některá sedadla zůstala neobsazená, pak {"$H_f \\neq B, H_f \\subset B$"}.
        </p>

        <br/>

        <p>
        Pokud platí, že {"$H_f = B$"}, říkáme že zobrazení je <strong>surjektivní</strong>{"$^1$"},
        neboli že je <strong>"na"</strong> (zobrazení <i>na množinu</i> {"$B$"}). Tedy nikoli jen 'do' množiny B, ale i 'na' množinu B.<br/>
        {"$^1$"} <i>Francouzsky 'sur' [syr] je 'na'.</i>
        </p>

        <p>Příklad 1:
        Zobrazení {"$3x + 1: \\mathbb{R} \\rightarrow \\mathbb{R}$"} je surjektivní. Pro každý prvek cílové množiny
        (tedy pro každé {"$y \\in \\mathbb{R}$"}) existuje vzor {"$x$"} z definičního oboru (z {"$\\mathbb{R}$"}),
        takový, že {"$3x + 1 = y$"}: stačí vzít {"$x = \\frac{y - 1}{3}$"}. Např. pro {"$y = 5$"} najdeme
        vzor {"$x = \\frac{4}{3}$"}, a podobně pro libovolné {"$y$"}.
        </p>

        <p>Příklad 2:
        Zobrazení {"$x^2: \\mathbb{R} \\rightarrow \\mathbb{R}$"} není surjektivní. Pro {"$y < 0$"} neexistuje vzor - neexistuje
        takové {"$x$"}, že by platilo {"$x^2 = y < 0$"}.
        <br/>
        Pokud ale změníme cílovou množinu na nezáporná reálná čísla, pak zobrazení surjektivní je:
        Zobrazení {"$x^2: \\mathbb{R} \\rightarrow \\langle 0, \\infty)$"} je surjektivní, protože pro každé číslo {"$y$"} z cílové
        množiny (z intervalu {"$\\langle 0, \\infty)$"}) existuje vzor (dokonce dva), který se na něj zobrazí:
        {"$\\sqrt{y}$"} a {"$-\\sqrt{y}$"}.
        </p>

        <p>Příklad 3:
        Funkce sinus jako zobrazení {"$\\sin: \\mathbb{R} \\rightarrow \\langle -1, 1\\rangle$"} je surjektivní.<br/>
        Funkce sinus jako zobrazení {"$\\sin: \\mathbb{R} \\rightarrow \\mathbb{R}$"} není surjektivní.
        </p>

        <p>
        Pokud zobrazení {"$f: A \\rightarrow B$"} není surjektivní, tak když cílovou množinu {"$B$"}
        zúžíme na obor hodnot {"$H_f$"}, dostaneme zobrazení, které surjektivní je: {"$f: A \\rightarrow H_f$"}.
        Toto jde vždy udělat.
        </p>

        <p>
        Když se zobrazení chová tak, že žádným dvěma různým vzorům nepřiřazuje stejný obraz,
        říkáme, že je <strong>prosté</strong>, neboli <strong>injektivní</strong>. Ať si vybereme
        jakýkoli obraz z cílové množiny, tak k němu nesmí existovat více než jeden vzor.
        </p>

        <p>Příklad 4:
        Funkce {"$x^2: \\mathbb{R} \\rightarrow \\langle 0, \\infty)$"} není prostá, protože
        každému obrazu {"$y$"} odpovídají dva vzory: {"$x_1 = \\sqrt{y}$"} a {"$x_2 = -\\sqrt{y}$"}.<br/>
        Funkce {"$x^2: \\langle 0, \\infty) \\rightarrow \\langle 0, \\infty)$"} je prostá,
        protože každému obrazu {"$y$"} odpovídá právě jeden vzor: {"$x = \\sqrt{y}$"}.
        </p>

        <p>
        Prosté zobrazení povoluje "reverse engineering" - když víme, jaká hodnota vyšla, víme
        jednoznačně, jaký byl argument funkce.
        </p>

        <p>
        Zobrazení, které je současně "na" a prosté, se nazývá <strong>bijekce</strong>. Je to
        "vzájemně jednoznačné zobrazení" - nejen že každý prvek definičního oboru má právě jeden obraz
        /to je definice zobrazení/, ale také každý prvek cílové množiny má právě jeden vzor.
        </p>

        <p>
        Je-li zobrazení {"$f: A \\rightarrow B$"} bijekce, potom logicky existuje inverzní
        zobrazení, které se značí {"$f^{-1}$"}, a je to zobrazení opačným/zpětným směrem: {"$f^{-1}: B \\rightarrow A$"},
        které prohazuje roli vzorů a obrazů:
        {`$$
            f^{-1}(y) = x \\Leftrightarrow f(x) = y.
        $$`}
        </p>

        <p>Příklad 5:
        Funkce {"$f(x) = 3x + 1$"} je bijekce, a inverzní funkce je {"$f^{-1}(y) = \\frac{y - 1}{3}$"}.
        Když funkce {"$f$"} zobrazí {"$\\frac{4}{3}$"} na {"$5$"}, pak inverzní funkce
        {"$f^{-1}$"} zobrazí {"$5$"} zpět na {"$\\frac{4}{3}$"}.
        </p>

        <p>
        Celá situace je ilustrovaná na obrázcích níže:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_1_negs} style={{width: "65%"}}/>
        </div>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_2_bijection} style={{width: "65%"}}/>
        </div>

        <p>
        Inverzní zobrazení odpovídá otočení šipek.
        </p>

        <p>
        Je vidět, že inverzní zobrazení existuje pouze tehdy, pokud původní zobrazení je:
        </p>
        <ul>
            <li><p>Surjektivní - jinak by {"$B$"} nemohlo být definičním oborem. Obrazy, které nemají vzor,
            by se staly vzory, které nemají obraz - ale u zobrazení musí mít každý vzor obraz.<br/>
            <i>Této komplikace se eventuálně zbavíme tak, že {"$B$"} zúžíme na {"$H_f$"} - obrazy
            bez vzorů vyloučíme.</i>
            </p></li>
            <li><p>Prosté - jinak by inverzní "věc" (relace) nebyla zobrazením -  obrazy, které mají více vzorů,
                by se při otočení šipek staly vzory, které mají 'více obrazů'.
                Zobrazení ale musí být jednoznačné - každému vzoru musí přiřazovat právě jednu hodnotu.<br/>
                <i>Této komplikace se eventuálně zbavíme tak, že zúžíme {"$A$"} -
                vyhodíme vzory "vedoucí k duplikovaným hodnotám" z definičního oboru.
                V případě {"$f(x) = x^2$"} zúžíme definiční obor buď na {"$\\langle 0, \\infty)$"},
                nebo na {"$(- \\infty, 0\\rangle$"}. K hodnotě {"$y$"} vedou
                vzory {"$x_1 = \\sqrt{y}$"} a {"$x_2 = -\\sqrt{y}$"}, buď tedy vyloučíme jeden,
                nebo druhý.
                </i>
            </p></li>
        </ul>

        <p>
        Pokud zobrazení {"$f$"} je bijekce, potom inverzní zobrazení {"$f^{-1}$"} je také bijekce, a platí:
        {`$$
            f^{-1}\\big(f(x)\\big) = x, \\quad f\\big(f^{-1}(y)\\big) = y,
        $$`}
        Neboli když zobrazení složíme s jeho inverzním zobrazením, dostaneme původní zobrazení.
        Zobrazení {"$f$"} a {"$f^{-1}$"} jsou vzájemně inverzní (situace je symetrická);
        platí {"$(f^{-1})^{-1} = f$"}.
        </p>

        {/* <p>
        Definiční obor je množina vzorů, pro které je zobrazení definované -
        z podstaty se tedy nemůže stát, že by zobrazení nějakému vzoru nepřiřazovalo
        žádný obraz. <i>(V obrázcích níže to znamená, že z každého puntíku vlevo vychází šipka; nemůže být puntík,
        z kterého by šipka nevedla.)</i>
        {/* Zobrazení také každému vzoru přiřazuje právě jeden obraz (ne více).
        <i>(V obrázcích níže to znamená, že z každého puntíku vlevo vychází přesně jedna šipka -
            nemůže se stát, že by z něj vycházely dvě nebo více šipek.)
        </i>
        </p> */}

        <h2 id="mapping-set-mapping">Množinové zobrazení</h2>

        <p>
        Vycházíme z toho, že máme zobrazení {"$f: X \\rightarrow Y$"}.
        Zobrazení může mít libovolné vlastnosti - teď je úplně jedno, jestli je "na" nebo ne,
        prosté nebo ne. Zobrazení funguje tak, že pro {"$x \\in X$"} dává hodnotu {"$f(x) \\in Y$"}.
        </p>

        <p>
        <i>(Pozn.: Oproti předchozí sekci jsme si přeznačili definiční obor na {"$X$"} a cílovou množinu na {"$Y$"} -
        to proto, že symbol {"$A$"} zde chceme využít pro jiný účel.)</i>
        </p>

        <p>
        My se teď k tomuto zobrazení {"$f$"} začneme chovat tak, že jako argument do něho 'strčíme'
        nikoli prvek {"$x \\in X$"}, ale rovnou celou množinu prvků {"$X$"}, neboli podmnožinu {"$A \\subseteq X$"}.
        </p>

        <p>
        Dejme tomu, že {"$X = \\{1, 2, 3\\}$"}, {"$Y = \\mathbb{R}$"}, a zobrazení je dáno vzorcem {"$f(x) = x^2$"},
        tedy {"$f(1) = 1$"}, {"$f(2) = 4$"}, {"$f(3) = 9$"}.
        </p>

        <p>
        Nyní napíšeme {"$f(\\{1, 2\\}) = \\cdots (?)$"}. Co to znamená?
        Myslíme tím {"$f(\\{1, 2\\}) = \\{1, 4\\}$"}.
        Do zobrazení strčíme množinu vzorů, a získáme množinu jejich obrazů.
        Obecně definujeme:
            {`$$
                f(A) = \\{ f(x) \\mid x \\in A \\}.
            $$`}
        Zobrazení {"$f$"} tedy aplikujeme jeden po druhém na všechny prvky {"$x$"} množiny {"$A$"},
        a výstupem je množina tvořená všemi výsledky {"$f(x)$"}, kterou zapisujeme jako {"$f(A)$"}.
        Můžeme to zapsat i takto:
        {`$$
            f\\big( \\{x_1, x_2, \\ldots, x_n\\} \\big) = \\big\\{ f(x_1), f(x_2) \\ldots, f(x_n) \\big\\}.
        $$`}
        </p>

        <p>
        Zkonstruovali jsem vlastně jiné (nové) zobrazení, jehož definičním oborem jsou všechny podmnožiny {"$X$"},
        tedy {"$D_f = \\mathcal{P}(X)$"} (potenční množina), a jehož hodnotami jsou množiny.
        Striktně vzato bychom pro toto zobrazení měli použít jiný symbol (protože to je jiné zobrazení!)
        - např. symbol {"$f^{\\{\\rightarrow\\}}$"} - ale praxe je taková, že se prostě používá stejný symbol {"$f$"}.
        V tabulce níže je vidět, že nové zobrazení má jiný definiční obor a jiný obor hodnot:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_table} style={{width: "65%"}}/>
        </div>

        <p>Obrázek níže ukazuje situaci schematicky a obecně:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_1a} style={{width: "27.5%"}}/>
        </div>

        <p>Pokud tuto logiku aplikujeme na známé funkce, máme např.:</p>
        {`$$
            \\sin \\Big(\\langle 0, \\pi\\rangle\\Big) = \\langle 0, 1\\rangle, \\quad \\ln \\Big(\\langle e^{-1}, e^2)\\Big) = \\langle -1, 2), \\quad e^{\\{1, 2, 3\\}} = \\{e, e^2, e^3\\}.
        $$`}

        <p>
        To nejlepší ale přijde až teď. Zobrazení {"$f$"} nemusí být bijekce,
        takže inverzní zobrazení {"$f^{-1}$"} nemusí existovat. My se k němu
        ale začneme chovat tak, jako by existovalo, a budeme do něj opět strkat množiny,
        tentokrát podmnožiny {"$A'$"} cílové množiny {"$Y$"}.
        </p>

        <p>
        Navažme na numerický příklad výše, a napišme {"$f^{-1}(\\{4, 9\\}) = \\cdots (?)$"}.
        Myslíme tím {"$f^{-1}(\\{4, 9\\}) = \\{2, 3\\}$"}. Do {"$f^{-1}$"} strčíme množinu obrazů
        původního zobrazení, a získáme množinu vzorů, které se na tyto obrazy zobrazují.
        Obecně definujeme:
        {`$$
            f^{-1}(A') = \\{ x \\in X \\mid f(x) \\in A' \\}.
        $$`}
        Procházíme tedy všechny prvky {"$y$"} zvolené množiny {"$A' \\subseteq Y$"},
        a zapisujeme si, které vzory {"$x \\in X$"} splňují {"$f(x) = y$"}; výstupem je
        množina všech těchto vzorů {"$x$"}, kterou zapisujeme jako {"$f^{-1}(A')$"}.
        </p>

        <p>
        Definičním oborem funkce {"$f^{-1}$"} jsou všechny podmnožiny množiny {"$Y$"},
        tedy potenční množina {"$\\mathcal{P}(Y)$"}.
        </p>

        <p>Do tabulky jsme přidali výpis funkce {"$f^{-1}$"}:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_table_inv1} style={{width: "65%"}}/>
        </div>

        <p>
        Tato tabulka by mohla vzbudit dojem, že množinové zobrazení {"$f^{-1}$"} je
        inverzní zobrazení k množinovému zobrazení {"$f$"}. Toto obecně neplatí,
        ale platí to tehdy, když je původní zobrazení {"$f$"} bijekce. Ukažme si případ,
        kdy {"$f$"} bijekce není:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_table_inv2} style={{width: "80%"}}/>
        </div>

        <p>Obrázek níže ukazuje situaci schematicky a obecně:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_1b} style={{width: "27.5%"}}/>
        </div>

        <p>Pokud bychom chtěli být ve značení přesní, pak bychom symboliku používali např. takto:</p>

        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>symbol zobrazení</th>
                    <th>odkud, kam</th>
                    <th>popis</th>
                    <th>existence</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$f$"}</td>
                    <td>{"$X \\rightarrow Y$"}</td>
                    <td></td>
                    <td>existuje (předpoklad)</td>
                </tr>
                <tr>
                    <td>{"$f^{-1}$"}</td>
                    <td>{"$Y \\rightarrow X$"}</td>
                    <td>inverzní zobrazení k {"$f$"}</td>
                    <td>nemusí existovat</td>
                </tr>
                <tr>
                    <td>{"$f^{\\{\\rightarrow\\}}$"}</td>
                    <td>{"$\\mathcal{P}(X) \\rightarrow \\mathcal{P}(Y)$"}</td>
                    <td>množinové zobrazení 'tam'</td>
                    <td>vždy existuje</td>
                </tr>
                <tr>
                    <td>{"$f^{\\{\\leftarrow\\}}$"}</td>
                    <td>{"$\\mathcal{P}(Y) \\rightarrow \\mathcal{P}(X)$"}</td>
                    <td>množinové zobrazení 'zpět'</td>
                    <td>vždy existuje</td>
                </tr>
            </tbody>
        </table>

        <p>S tímto označením bychom dokázali srozumitelněji zformulovat:
        Zobrazení {"$f^{\\{\\leftarrow\\}}$"} je inverzním zobrazením
        k zobrazení {"$f^{\\{\\rightarrow\\}}$"} právě tehdy, když zobrazení {"$f$"} je bijekce,
        tj. když existuje inverzní zobrazení {"$f^{-1}$"}.
        Pokud zobrazení {"$f$"} není bijekce, pak zobrazení
        {"$f^{\\{\\rightarrow\\}}$"} a {"$f^{\\{\\leftarrow\\}}$"} nejsou vzájemně inverzní.
        </p>

        <p>Lze dokázat, že:</p>
        <ul>
            <li><p>Obecně je {"$f\\big(f^{-1}(A')\\big) \\subseteq A'$"}. Je-li {"$f$"} surjekce, pak {"$f\\big(f^{-1}(A')\\big) = A'$"}.
            </p></li>
            <li><p>Obecně je {"$f^{-1}\\big(f(A)\\big) \\supseteq A$"}. Je-li {"$f$"} prosté, pak {"$f^{-1}\\big(f(A)\\big) = A$"}.
            </p></li>
        </ul>
        <p>Tato situace je ilustrována na diagramech níže:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_bf} style={{width: 420}}/>
        </div>


        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mapping_sets_fb} style={{width: 390}}/>
        </div>

        {/* MAYBE ADD: poznámka o rozkladu: { f{-1}(y) | y \in Y} je rozklad množiny X */}

        <h2 id="mapping-use-in-probability">Použití v pravděpodobnosti</h2>

        <p>
        Zobrazení je náhodná veličina {"$X: \\Omega \\rightarrow \\mathbb{R}$"}.
        Při definování přenesené pravděpodobnosti z {"$\\Omega$"} na {"$\\mathbb{R}$"} používáme předpis
        {`$$
            P'(A') = P\\Big(X^{-1}(A')\\Big).
        $$`}
        V něm {"$X^{-1}(A')$"} je množina všech elementárních jevů, které {"$X$"} zobrazí na množinu {"$A'$"}.
        Používáme zde tedy zpětné množinové zobrazení {"$X^{-1}$"}.
        Provedení přenosu je ilustrováno na obrázku níže:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={prenos_miry} style={{width: "80%"}}/>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}
