import React from "react";

interface ExternalLinkProps {
    href: string;
    children: React.ReactNode;
}

export const ELink: React.FC<ExternalLinkProps> = ({ href, children }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" className="external-link">
            {children}
        </a>
    );
};
