import React from 'react';

import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useLocation,
} from "react-router-dom";

import 'janstatistics/lib/src/assets/css/statistics.css';

import './App.css';
import { ContentsMenu } from './Menu';

import { HistogramPane } from 'janstatistics';

import { AuthorProfiles } from './AuthorProfiles';
import { LandingPage } from './LandingPage';
import { NavBar } from './NavBar';
import { Path } from './paths';
import { Sources } from './Sources';

import {
    BarChartMeta,
    ChebychevInequalityMeta,
    CLTMeta,
    ConceptComparisonDMeta,
    ConceptComparisonEMeta,
    CountabilityMeta,
    DataTypesMeta,
    DensityMeta,
    DescriptiveStatisticsMeta,
    DiscreteOrContinuousMeta,
    DistributionFunctionMeta,
    DistributionMeta,
    ElementaryEventsMeta,
    EventsIndependenceMeta,
    EventsMeta,
    EventSpaceMeta,
    HistogramMeta,
    HypothesisTestingMeta,
    InferenceIntroMeta,
    IntervalEstimationMeta,
    LinearRegressionMeta,
    MappingMeta,
    MarkovInequalityMeta,
    MathSectionMeta,
    MeanCalculationMeta,
    MeanKindsMeta,
    MomentsMeta,
    NahodnyVyberMeta,
    NotationMeta,
    PointEstimationMeta,
    ProbabilityIntroMeta,
    ProbabilityMeta,
    QuantilesMeta,
    RandomVariable2Meta,
    RandomVariableMeta,
    RandomVectorsMeta,
    RegressionBasicsMeta,
    RVTransformationsMeta,
    SetsAMeta,
    SetsMeta,
    VarianceMeta,
} from './chapters';

import { Section, sections } from './defs';
import { Dictionary } from './Dictionary';
import { FooterMini } from './Footer';
import { version } from './version';


const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes('histogram-app');

    return (
        <div className="App" style={{display: "flex", gap: 0, width: "100vw"}}>
            <div className="sidebar" style={{display: "flex", flexDirection: "column"}}>
                <NavBar/>
                <div className='menu-container' style={{flexGrow: 1}}>
                    <ContentsMenu sections={sections}/>
                </div>
                <div className="small-text">verze z {version}</div>
                <FooterMini />
            </div>
            <div className="page" style={{flexBasis: isHistogramApp ? '0' : '6', flexGrow: 6}}>
                {/* {isHistogramApp ? null : <div style={{flexBasis: 0, flexGrow: 1, minWidth: 0}}/>} */}
                {/* <div style={{flexGrow: 1, maxWidth: isHistogramApp ? 'none' : 700}}> */}

                {/* <div style={{width: "100%", height: "100%"}}>*/}

                <Outlet />


                {/* </div> */}
                {/* {isHistogramApp ? null : <div style={{flexBasis: 0, flexGrow: 1, minWidth: 0}}/>} */}
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    // console.log("Creating router child for", section.title, section.sectionNumber);
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
        // children: section.sections?.map(crouterChild) || [],
    }

}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout/>,
        children: [
            {
                path: '',
                element: (
                    <LandingPage/>
                )
            },
            {
                path: 'authors',
                element: (
                    <AuthorProfiles/>
                )
            },
            {
                path: 'sources',
                element: (
                    <Sources/>
                )
            },
            {
                path: 'histogram-app',
                element: (
                    <HistogramPane/>
                )
            },
            {
                path: 'dictionary',
                element: (
                    <Dictionary/>
                )
            },
            routerChild(BarChartMeta),
            routerChild(ChebychevInequalityMeta),
            routerChild(CLTMeta),
            routerChild(ConceptComparisonDMeta),
            routerChild(ConceptComparisonEMeta),
            routerChild(CountabilityMeta),
            routerChild(DataTypesMeta),
            routerChild(DescriptiveStatisticsMeta),
            routerChild(DensityMeta),
            routerChild(DiscreteOrContinuousMeta),
            routerChild(DistributionMeta),
            routerChild(DistributionFunctionMeta),
            routerChild(ElementaryEventsMeta),
            routerChild(EventsIndependenceMeta),
            routerChild(EventsMeta),
            routerChild(EventSpaceMeta),
            routerChild(HistogramMeta),
            routerChild(HypothesisTestingMeta),
            routerChild(InferenceIntroMeta),
            routerChild(IntervalEstimationMeta),
            routerChild(LinearRegressionMeta),
            routerChild(MappingMeta),
            routerChild(MarkovInequalityMeta),
            routerChild(MathSectionMeta),
            routerChild(MeanCalculationMeta),
            routerChild(MeanKindsMeta),
            routerChild(MomentsMeta),
            routerChild(NahodnyVyberMeta),
            routerChild(NotationMeta),
            routerChild(PointEstimationMeta),
            routerChild(ProbabilityIntroMeta),
            routerChild(ProbabilityMeta),
            routerChild(QuantilesMeta),
            routerChild(RandomVariableMeta),
            routerChild(RandomVariable2Meta),
            routerChild(RandomVectorsMeta),
            routerChild(RegressionBasicsMeta),
            routerChild(RVTransformationsMeta),
            routerChild(SetsAMeta),
            routerChild(SetsMeta),
            routerChild(VarianceMeta),
        ],
    }
]);


export default function App() {
    return (
        <RouterProvider router={router} />
    );
}


