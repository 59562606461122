import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { ELink } from '~/ExternalLink';


export const DiscreteOrContinuousMeta = {
    title: "(Poznámky k náhodné veličině)",
    shortTitle: "(Poznámky k náh. vel.)",
    path: Path.discrete_or_continuous,
    element: () => <DiscreteOrContinuous />,
    sectionNumber: "",
}


export function DiscreteOrContinuous() {
    return (
        <ChapterWrapper sectionNumber={DiscreteOrContinuousMeta.sectionNumber} title={DiscreteOrContinuousMeta.title}>

            <p>
            Pokud Vám stačí intuitivní pochopení diskrétního a spojitého znaku (náhodné veličiny),
            potom tuto sekci nemusíte číst. Pokud Vás zajímá, jak je to s diskrétní/spojitou
            náhodnou veličinou, potom čtěte dál.
            </p>

            <h2>Spojitost</h2>

            <p>V matematice se pojmy diskrétní a spojitý používají v různých kontextech.
            Ve statistice je toto použití specifické, a analogie s jinými oblastmi matematiky není stoprocentní.
            </p>

            <p><strong>Diskrétní</strong> náhodná veličina je taková veličina, která nabývá pouze konečně nebo spočetně mnoha různých hodnot.
            Platí {"$\\sum_{x \\in H} P(x) = 1$"}. Tato suma je buď konečná, nebo spočetná (tedy konvergentní řada); {"$H$"} značí (konečný nebo spočetný)
            obor hodnot.
            </p>

            <p>
            <strong>Spojitá</strong> náhodná veličina je taková, pro kterou existuje hustota, tj. integrovatelná funkce {"$f(x)$"},
            která splňuje {"$P(a \\leq X \\leq b) = \\int_a^b f(x) \\mathrm{d}x$"}.
            Ekvivalentně: Pravděpodobnostní míra na {"$\\mathbb{R}$"} indukovaná touto náhodnou veličinou je absolutně spojitá vůči Lebesgueově míře.
            Ekvivalentně: Distribuční funkce této náhodné veličiny je absolutně spojitá funkce.
            </p>

            <p>
            Učební texty někdy používají alternativní 'definici', že spojitá náhodná veličina:
            </p>
            <ul className='no-bullets'>
                <li>1) nabývá nespočetně mnoha hodnot z nějakého intervalu nebo z celého {"$\\mathbb{R}$"} a</li>
                <li>2) každá konkrétní hodnota má nulovou pravděpodobnost.</li>
            </ul>

            <p>
            Tato 'definice' je OK pro běžnou praxi, ale technicky není přesná.
            Náhodná veličina, jejíž distribuční funkcí je Cantorova funkce, splňuje podmínku 1) (obor hodnot je Cantorova množina, která je nespočetná)
            i 2) (každá konkrétní hodnota má nulovou pravděpodobnost), ale neexistuje k ní hustota; distribuční funkce není absolutně spojitá.
            Tento případ je rozebrán v <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_1/pages/03_07-singularni-rozdeleni.html"}>{"učebním textu MUNI"}</ELink>
            &nbsp;a taková náhodná veličina se nazývá <strong>singulární</strong>.
            (Cantorova funkce je vysvětlena i na <ELink href={"https://en.wikipedia.org/wiki/Cantor_function"}>wikipedii</ELink>.)
            </p>
            <p>
            Zjednodušená 'definice' spojité náhodné veličiny tedy ignoruje singulární rozdělení. Některé texty pro zdůraznění role absolutní spojitosti
            nepoužívají termín spojitá náhodná veličina vůbec, ale místo toho mluví o náhodné veličině "absolutně spojitého typu." Je to ale spíše výjimka.
            </p>
            <p>
            Přesná definice s hustotou samozřejmě implikuje vlastnosti 1) a 2).
            Pokud by nebyl splněn bod 2), potom v hodnotě, která má nenulovou pravděpodobnost, by distribuční funkce měla skok, a tedy by nebyla spojitá.
            Nesplnění bodu 1) automaticky vede k nesplnění bodu 2), protože aby platilo {"$\\sum_{x \\in H} P(x) = 1$"}, musí být pravděpodobnost
            alespoň jedné hodnoty {"$x$"} nenulová.
            </p>

            <p>Ne každá náhodná veličina je čistě diskrétní, spojitá nebo singulární.
            Existují i náhodné veličiny, které mají 'smíšené' rozdělení.
            Např. náhodná veličina, která má distribuční funkci diferencovatelnou a spojitou všude kromě jednoho bodu,
            ve kterém je skok (tento bod má nenulovou pravděpodobnost), má 'smíšené' rozdělení.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

