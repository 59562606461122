import React, { useEffect, useState } from 'react';

import Plot from 'react-plotly.js';
import { Path } from '~/paths';

import { jStat } from 'jstat';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';

export const CLTMeta = {
    title: "Centrální limitní věta",
    shortTitle: "CLV",
    path: Path.clt,
    element: (sectionNumber:string) => <CLT sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


const layoutDefault = {
    height: 250,
    margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 0,
        pad: 4
    },
    xaxis: {
        range: [0, 20],
    },
    yaxis: {
        min: 0,
    }
}


function binomialDistribution(n: number, p: number): number[] {
    const result = [];
    for (let k = 0; k <= n; k++) {
        result.push(jStat.binomial.pdf(k, n, p));
    }
    return result;
}

function binomialRange(n: number): number[] {
    return Array.from({length: n+1}, (_, i) => i);
}

function binomialAvgRange(n: number): number[] {
    return Array.from({length: n+1}, (_, i) => i/n);
}

function binomialStandardizedRange(n: number, p:number): number[] {
    const mu = n * p;
    const sigma = Math.sqrt(n * p * (1 - p));
    return Array.from({length: n+1}, (_, i) => (i - mu) / sigma);
}


function normalize(arr: number[]): number[] {
    const n = arr.length;
    return arr.map(x => x / n);
}

class Distribution {
    values: number[];
    probabilities: number[];

    constructor(values: number[], probabilities: number[]) {
        this.values = values;
        this.probabilities = probabilities;
    }
}

function sampleDistribution(distribution: Distribution, n: number): Distribution {
    const { values, probabilities } = distribution; // VALUES MUST BE DISTINCT (OK)
    // todo cache lookup valueIndex = values.indexOf(value);

    // Generate all possible combinations of sample means
    const sampleMeans: { [key: number]: number } = {};

    function calculateSampleMeans(currentSample: number[], index: number) {
        if (currentSample.length === n) {
            const mean = currentSample.reduce((sum, value) => sum + value, 0); /// n;
            if (sampleMeans[mean] === undefined) {
                sampleMeans[mean] = 0;
            }
            // Calculate the probability of this sample
            const sampleProbability = currentSample.reduce((product, value) => {
                const valueIndex = values.indexOf(value);
                return product * probabilities[valueIndex];
            }, 1);
            sampleMeans[mean] += sampleProbability;
            return;
        }

        for (let i = 0; i < values.length; i++) {
            calculateSampleMeans([...currentSample, values[i]], i);
        }
    }

    calculateSampleMeans([], 0);

    const newValues = Object.keys(sampleMeans).map(Number);
    const newProbabilities = Object.values(sampleMeans);

    return new Distribution(newValues, newProbabilities);
}

export function BinomPlot({}:{}) {

    const [n, setN] = useState<number>(10);
    const [p, setP] = useState<number>(0.5);

    const [sameAxisScale, setSameAxisScale] = useState<boolean>(false);
    const [axisRange, setAxisRange] = useState<[number, number]>([0, 20]);

    const layoutDefault = {
        height: 250,
        margin: {
            l: 50,
            r: 50,
            b: 50,
            t: 0,
            pad: 4
        },
        xaxis: {
            range: sameAxisScale ? axisRange : undefined,
        }
    }

    return (

        <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

            <h2>Součet {"$S_n$"}</h2>
            <Plot data={[
                    {
                        x: binomialRange(n),
                        y: binomialDistribution(n, p),
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(200, 50, 50)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <h2>Výběrový průměr {"$\\bar{X}_n$"}</h2>
            <Plot data={[
                    {
                        x: binomialAvgRange(n),
                        y: binomialDistribution(n, p),
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(50, 200, 50)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <h2>Standardizovaný součet/výběrový průměr {"$Z_n$"}</h2>
            <Plot data={[
                    {
                        x: binomialStandardizedRange(n, p),
                        y: binomialDistribution(n, p),
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(50, 50, 200)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <div style={{"padding": 5}}>
                <label >
                    n:
                    <input type="number" value={n} onChange={(e) => setN(Number(e.target.value))} />
                </label>
                <label>
                    p:
                    <input type="number" step="0.01" value={p} onChange={(e) => setP(Number(e.target.value))} />
                </label>
            </div>
            <div style={{"padding": 5}}>
                <label>
                Stejné měřítko na osách:
                <input
                    style={{"padding": 5}}
                    type="checkbox"
                    checked={sameAxisScale}
                    onChange={(e) => setSameAxisScale(e.target.checked)}
                />
                </label>
                {sameAxisScale && (
                <div style={{"padding": 5}}>
                    <label>
                    Rozsah osy x:
                    <input
                        type="number"
                        value={axisRange[0]}
                        onChange={(e) => setAxisRange([Number(e.target.value), axisRange[1]])}
                    />
                    <input
                        type="number"
                        value={axisRange[1]}
                        onChange={(e) => setAxisRange([axisRange[0], Number(e.target.value)])}
                    />
                    </label>
                </div>
                )}
            </div>
        </div>
    );
}

function multiplyArray(values: number[], multiplier: number): number[] {
    return values.map(value => value * multiplier);
  }


export function GenericPlot({}:{}) {

    const [input1, setInput1] = useState<string>('1;2;3;4');
    const [input2, setInput2] = useState<string>('0.4;0.1;0.1;0.4');
    const [values, setValues] = useState<number[]>([]);
    const [probabilities, setProbabilities] = useState<number[]>([]);

    const [mu, setMu] = useState<number>(0);
    const [std, setStd] = useState<number>(1);

    const [n, setN] = useState<number>(10);

    const [distributionSum, setDistributionSum] = useState<Distribution | null>(null);
    const [distributionMean, setDistributionMean] = useState<Distribution | null>(null);
    const [distributionStandard, setDistributionStandard] = useState<Distribution | null>(null);

    const [sameAxisScale, setSameAxisScale] = useState<boolean>(false);
    const [axisRange, setAxisRange] = useState<[number, number]>([0, 20]);

    const layoutDefault = {
        height: 250,
        margin: {
            l: 50,
            r: 50,
            b: 50,
            t: 0,
            pad: 4
        },
        xaxis: {
            range: sameAxisScale ? axisRange : undefined,
        }
    }

    const handleSubmit = () => {
        const vals = input1.split(';').map(str => parseFloat(str.trim())).filter(num => !isNaN(num));
        const probs = input2.split(';').map(str => parseFloat(str.trim())).filter(num => !isNaN(num));

        setValues(vals);
        setProbabilities(probs);

        // console.log('Array 1:', array1);
        // console.log('Array 2:', array2);

        const n = vals.length;
        const mu = vals.reduce((acc, val, i) => acc + val * probs[i], 0);
        const std = Math.sqrt(vals.reduce((acc, val, i) => acc + Math.pow(val - mu, 2) * probs[i], 0));

        setMu(mu);
        setStd(std);
      };

    useEffect(() => {
        const distribution = new Distribution(values, probabilities);
        const dSUM = sampleDistribution(distribution, n)
        setDistributionSum(dSUM);
        setDistributionMean(new Distribution(multiplyArray(dSUM.values, 1/n), dSUM.probabilities));
        setDistributionStandard(new Distribution(dSUM.values.map(x => (x - n*mu)/(Math.sqrt(n) * std)), dSUM.probabilities));

    }, [values, probabilities, n, mu, std]);


    return (

        <div style={{width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

            <div>
                <div>
                    <label>
                    Hodnoty {"$x$"}:
                    <input
                    type="text"
                    value={input1}
                    onChange={(e) => setInput1(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                    />
                    </label>
                </div>
                <div>
                    <label>
                    Pravděpodobnosti {"$P(X = x)$"}:
                    <input
                    type="text"
                    value={input2}
                    onChange={(e) => setInput2(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                    />
                    </label>
                </div>
                <button onClick={handleSubmit}>Submit</button>
                </div>


            <h2>Součet {"$S_n$"}</h2>
            <Plot data={[
                    {
                        x: distributionSum?.values,
                        y: distributionSum?.probabilities,
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(200, 50, 50)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <h2>Výběrový průměr {"$\\bar{X}_n$"}</h2>
            <Plot data={[
                    {
                        x: distributionMean?.values,
                        y: distributionMean?.probabilities,
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(50, 200, 50)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <h2>Standardizovaný součet/výběrový průměr {"$Z_n$"}</h2>
            <Plot data={[
                    {
                        x: distributionStandard?.values,
                        y: distributionStandard?.probabilities,
                        type: 'scatter',
                        mode: 'markers',
                        marker: {
                            color: 'rgb(50, 50, 200)', // 'rgb(150, 205, 191)',
                            opacity: 0.8,
                        }
                    }
                ]}
                layout={layoutDefault}
            />

            <div style={{"padding": 5}}>
                <label >
                    n:
                    <input type="number" value={n} onChange={(e) => setN(Number(e.target.value))} />
                </label>
            </div>
            <div style={{"padding": 5}}>
                <label>
                Stejné měřítko na osách:
                <input
                    style={{"padding": 5}}
                    type="checkbox"
                    checked={sameAxisScale}
                    onChange={(e) => setSameAxisScale(e.target.checked)}
                />
                </label>
                {sameAxisScale && (
                <div style={{"padding": 5}}>
                    <label>
                    Rozsah osy x:
                    <input
                        type="number"
                        value={axisRange[0]}
                        onChange={(e) => setAxisRange([Number(e.target.value), axisRange[1]])}
                    />
                    <input
                        type="number"
                        value={axisRange[1]}
                        onChange={(e) => setAxisRange([axisRange[0], Number(e.target.value)])}
                    />
                    </label>
                </div>
                )}
            </div>
        </div>
    );
}



const TOCSpec = [
    "clt-preparation",
    "clt-interpretation",
    "clt-plots-alt-distribution",
    "clt-plots-generic-distribution",
];


export function CLT({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={CLTMeta.title} TOCSpec={TOCSpec}>

            <h2 id="clt-preparation">Příprava</h2>
            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Popis</th>
                        <th>Náhodná veličina</th>
                        <th>Střední hodnota</th>
                        <th>Rozptyl</th>
                        <th>Směrodatná odchylka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>výchozí náhodná veličina</td>
                        <td>{"$X$"}</td>
                        <td>{"$\\mu$"}</td>
                        <td>{"$\\sigma^2$"}</td>
                        <td>{"$\\sigma$"}</td>
                    </tr>
                    <tr>
                        <td>součet náhodného výběru</td>
                        <td>{"$S_n = \\sum_{i=1}^{n} X_i$"}</td>
                        <td>{"$n\\mu$"}</td>
                        <td>{"$n\\sigma^2$"}</td>
                        <td>{"$\\sqrt{n}\\sigma$"}</td>
                    </tr>
                    <tr>
                        <td>výběrový průměr</td>
                        <td>{"$\\bar{X}_n = \\frac{1}{n}\\sum_{i=1}^{n} X_i$"}</td>
                        <td>{"$\\mu$"}</td>
                        <td>{"$\\frac{\\sigma^2}{n}$"}</td>
                        <td>{"$\\frac{\\sigma}{\\sqrt{n}}$"}</td>
                    </tr>
                    <tr>
                        <td>standardizovaný výběrový průměr</td>
                        <td>{"$Z_n = \\frac{\\bar{X}_n - \\mu}{\\sigma / \\sqrt{n}}$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$1$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>Tabulku čteme takto:</p>

            <p>
            Máme nějakou původní náhodnou veličinu {"$X: \\Omega \\rightarrow \\mathbb{R}$"},
            která má střední hodnotu {"$E(X) = \\mu$"} a rozptyl {"$D(X) = \\sigma^2$"}.
            </p>

            <p>
            Uděláme náhodný výběr: Vytvoříme kartézský součin {"$\\mathbb{R}^n$"} a náhodnou
            veličinu {"$X$"} zreplikujeme nezávisle na každé souřadnici - to znamená,
            že na {"$\\mathbb{R}^n$"} definujeme pravděpodobnost pomocí součinu {"$n$"} hustot
            (nebo pravděpodobnostních funkcí) výchozí náhodné
            veličiny: {"$\\tilde{f}(x_1, x_2, \\ldots x_n) = f(x_1) f(x_2) \\cdots f(x_n)$"}.
            Veličiny {"$X_i$"} pak definujeme jako projekce na i-tou souřadnici: {"$X_i(x_1, x_2, \\ldots, x_n) = x_i$"}.
            Takto zkonstruované veličiny jsou vzájemně nezávislé, a mají stejné rozdělení
            pravděpodobnosti jako původní veličina {"$X$"}.
            </p>

            <p>Uděláme součet náhodného výběru: {"$S_n = \\sum_{i=1}^{n} X_i$"}.
            Z kapitoly o transformacích víme, že
            {`$$\\begin{align*}
                E(S_n) &= E\\Big(\\sum_{i=1}^{n} X_i\\Big) = \\sum_{i=1}^{n} E(X_i) = \\sum_{i=1}^{n} E(X) = nE(X) = n\\mu, \\\\
                D(S_n) &= D\\Big(\\sum_{i=1}^{n} X_i\\Big) = \\mid \\textrm{nezávislost} \\mid = \\sum_{i=1}^{n} D(X_i) = \\sum_{i=1}^{n} D(X) = nD(X) = n\\sigma^2.
            \\end{align*}$$`}
            </p>

            <p>Uděláme výběrový průměr: {"$\\bar{X}_n = \\frac{1}{n}\\sum_{i=1}^{n} X_i = \\frac{1}{n}S_n$"}.
            Z kapitoly o transformacích víme, že
            {`$$\\begin{align*}
                E(\\bar{X}_n) &= E\\Big(\\frac{1}{n}S_n\\Big) = \\frac{1}{n}E(S_n) = \\frac{1}{n}n\\mu = \\mu, \\\\
                D(\\bar{X}_n) &= D\\Big(\\frac{1}{n}S_n\\Big) = \\frac{1}{n^2}D(S_n) = \\frac{1}{n^2}n\\sigma^2 = \\frac{\\sigma^2}{n}.
            \\end{align*}$$`}
            </p>

            <p>Standardizujeme výběrový průměr: {"$Z_n = \\frac{\\bar{X}_n - \\mu}{\\sigma / \\sqrt{n}} = \\frac{\\sqrt{n}}{\\sigma}(\\bar{X}_n - \\mu).$"}&nbsp;
            Z kapitoly o transformacích víme, že
            {`$$\\begin{align*}
                E(Z_n) &= E\\Big[\\frac{\\sqrt{n}}{\\sigma}(\\bar{X}_n - \\mu)\\Big] = \\frac{\\sqrt{n}}{\\sigma}E(\\bar{X}_n - \\mu) = \\frac{\\sqrt{n}}{\\sigma}(\\mu - \\mu) = 0, \\\\
                D(Z_n) &= D\\Big[\\frac{\\sqrt{n}}{\\sigma}(\\bar{X}_n - \\mu)\\Big] = \\frac{n}{\\sigma^2}D(\\bar{X}_n) = \\frac{n}{\\sigma^2}\\frac{\\sigma^2}{n} = 1.
            \\end{align*}$$`}
            </p>

            <p>Kdybychom nestandardizovali výběrový průměr, ale součet {"$S_n$"}, dopadlo by to stejně:
            {`$$\\begin{align*}
                S_n        & \\rightarrow \\frac{S_n - n\\mu}{\\sqrt{n}\\sigma} \\\\
                \\bar{X}_n & \\rightarrow \\frac{\\bar{X}_n - \\mu}{\\frac{\\sigma}{\\sqrt{n}}} =  \\frac{\\frac{1}{n} S_n - \\mu}{\\frac{\\sigma}{\\sqrt{n}}} = \\frac{S_n - n \\mu}{n\\frac{\\sigma}{\\sqrt{n}}} =  \\frac{S_n - n\\mu}{\\sqrt{n}\\sigma}.
            \\end{align*}$$`}
            </p>

            <h3 id="clt-interpretation">Interpretace</h3>

            <p>Podíváme se, jak se rozdělení pravděpodobnosti mění s rostoucím {"$n$"}.</p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Popis</th>
                        <th>Náhodná veličina</th>
                        <th>Střední hodnota</th>
                        <th>Rozptyl</th>
                        <th>Směrodatná odchylka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>součet náhodného výběru</td>
                        <td>{"$S_n = \\sum_{i=1}^{n} X_i$"}</td>
                        <td>{"$n\\mu$"}</td>
                        <td>{"$n\\sigma^2$"}</td>
                        <td>{"$\\sqrt{n}\\sigma$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Rozdělení nám "cestuje doprava" (pokud {"$\\mu > 0$"}) nebo doleva (pokud {"$\\mu < 0$"}).
            Střední hodnota je totiž {"$2\\mu$"} pro {"$n=2$"}, {"$3\\mu$"} pro {"$n=3$"}, atd.
            Zároveň se rozdělení "rozplizává" - zvětšuje se jeho rozptyl.
            </p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Popis</th>
                        <th>Náhodná veličina</th>
                        <th>Střední hodnota</th>
                        <th>Rozptyl</th>
                        <th>Směrodatná odchylka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>výběrový průměr</td>
                        <td>{"$\\bar{X}_n = \\frac{1}{n}\\sum_{i=1}^{n} X_i$"}</td>
                        <td>{"$\\mu$"}</td>
                        <td>{"$\\frac{\\sigma^2}{n}$"}</td>
                        <td>{"$\\frac{\\sigma}{\\sqrt{n}}$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>
            Rozdělení sedí "přišpendlené na místě" (střední hodnota je stále stejná, rovna {"$\\mu$"}).
            Zároveň se rozdělení "zužuje"/"koncentruje" - zmenšuje se jeho rozptyl.
            Rozptyl je totiž {"$\\frac{\\sigma^2}{2}$"} pro {"$n=2$"}, {"$\\frac{\\sigma^2}{3}$"} pro {"$n=3$"}, atd.
            </p>

            <table className="simple-table simple-table-basic">
                <thead>
                    <tr>
                        <th>Popis</th>
                        <th>Náhodná veličina</th>
                        <th>Střední hodnota</th>
                        <th>Rozptyl</th>
                        <th>Směrodatná odchylka</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>standardizovaný výběrový průměr</td>
                        <td>{"$Z_n = \\frac{\\bar{X}_n - \\mu}{\\sigma / \\sqrt{n}}$"}</td>
                        <td>{"$0$"}</td>
                        <td>{"$1$"}</td>
                        <td>{"$1$"}</td>
                    </tr>
                </tbody>
            </table>

            <p>Rozdělení sedí nacentrované na nule, a má stále stejný rozptyl.
            Jediné co se tedy reálně mění, jsou detaily jeho tvaru.
            </p>

            <p>
            <strong>Centrální limitní věta</strong> říká, že s rostoucím {"$n$"} se tvar
            standardizovaného rozdělení {"$Z_n$"} přibližuje k tvaru normálnímu rozdělení N(0,1) - ke Gaussově křivce.
            Pro {"$n \\rightarrow \\infty$"} se mu limitně blíží.
            </p>

            <h2 id="clt-plots-alt-distribution">Ilustrace na Alt(p) / Bi(n, p)</h2>

            <p>
            Za výchozí náhodnou veličinu vezmeme veličinu s alternativním rozdělením {"$X \\sim \\text{Alt}(p).$"}
            </p>

            <p>
            Je dobře známo, že rozdělení součtu {"$S_n$"} je v tom případě binomické: {"$S_n \\sim \\text{Bi}(n, p)$"}.
            </p>

            <p>Grafy níže ukazují všechna tři rozdělení. Pokud zaškrtnete "stejné měřítko na osách",
            uvidíte, jak {"$S_n$"} cestuje doprava, {"$\\bar{X}_n$"} sedí na místě a zužuje se,
            a {"$Z_n$"} se "formuje" do tvaru normálního rozdělení. Aby mohlo být ve všech třech grafech
            na osách stejné měřítko, je potřeba zvolit na ose {"$x$"} pevný rozsah (např. -10 až 10).
            </p>

            <BinomPlot/>

            <h2 id="clt-plots-generic-distribution">Ilustrace na libovolném diskrétním rozdělení</h2>

            <p>Zásadním sdělením centrální limitní věty je to, že nezáleží, jaké je původní rozdělení veličiny {"$X$"}.
            Může být klidně asymetrické, nebo mít vysoké pravděpodobnosti na krajích a malé uprostřed.
            Stejně nakonec výběrový průměry {"$\\bar{X}_n$"} postupně získá tvar normálního rozdělení.
            </p>

            <p>
            Za výchozí náhodnou veličinu vezmeme veličinu s ručně zadaným diskrétním rozdělením.
            Hodnoty {"$x$"} a {"$P(X = x)$"} zadejte oddělené středníkem.
            </p>

            <p>Aplikace je responzivní pro 4 hodnoty {"$x$"} cca po {"$n = 12$"} - pro
            vyšší hodnoty {"$n$"} je výpočet příliš pomalý a zamrzne Vám prohlížeč.
            Není se co divit - pro {"$n = 12$"} je potřeba napočítat {"$4^{12} = 16 777 216$"} pravděpodobností;
            a implementace není zatím optimalizovaná.
            </p>

            <GenericPlot/>

            <Footer/>
            </ChapterWrapper>
    );
}

