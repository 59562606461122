import React from 'react';
import { ELink } from './ExternalLink';


export function Sources()  {

    return (
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">
            <h1>Doporučená literatura</h1>

            <p>FREEDMAN, David, PISANI, Robert, PURVES, Roger. <i>Statistics.</i> 4. vyd. W. W. Norton & Company, 2007. ISBN 13-978-0-393-92972-0.</p>
            <p>ZAR, Jerrold H. <i>Biostatistical Analysis.</i> 5. vyd. Pearson Education Limited, 2014. ISBN 978-1-292-02404-2.</p>
            <p>KARPÍŠEK, Zdeněk. <i>Matematika IV.</i> 2. vyd. Brno: Akademické nakladatelství CERM, 2003. ISBN 80-214-2522-9.</p>


            <h1>Online zdroje</h1>

            <p>
            Kurz z Berkeley, autor <ELink href={"https://www.stat.berkeley.edu/~stark/SticiGui/index.htm"}>P. B. Stark</ELink>.<br/>
            </p>

            <p>
            Kurz MU <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_1/index.html"}>Pravděpodobnost a statistika I</ELink>.<br/>
            Kurz MU <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/index.html"}>Pravděpodobnost a statistika II</ELink>.<br/>
            Interaktivní kurz MU <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps15/statistika/web/index.html"}>Statistika a pravděpodobnost</ELink>.<br/>
            Skripta k přednášce od <ELink href={"https://www.karlin.mff.cuni.cz/~komarek/vyuka/2023_24/nmsa331/ms1.pdf"}>doc. Kulicha</ELink> na UK.<br/>
            Materiály <ELink href='https://www.karlin.mff.cuni.cz/~zichova/'>na UK</ELink>.<br/>
            Materiály <ELink href={"https://math.fme.vutbr.cz/Home/karpisek/soubory-ke-stazeni"}> docenta Karpíška</ELink>.<br/>
            Materály <ELink href='https://cmp.felk.cvut.cz/~navara/stat/PMS_ebook.pdf'>ČVUT</ELink> (prof. Navara).<br/>
            Online učebnice <ELink href={"https://publi.cz/books/201/Obsah.html"}>LF UK</ELink> (doktor Záhora).<br/>
            Skripta <ELink href={"https://www.physics.muni.cz/ufkl/Vyuka/files/JosefHumlicek-StatZpracMereni-1984.pdf" }>prof. Humlíčka</ELink>.<br/>
            </p>

            <p>
            Webový kurz <ELink href={'https://www.statology.org/'}>Statology</ELink>,&nbsp;
            <ELink href={"https://www.statlect.com/"}>StatLect</ELink>,&nbsp;
            <ELink href={"https://www.khanacademy.org/math/statistics-probability"}>Khan Academy</ELink>.<br/>
            YouTube kanál <ELink href={"https://www.youtube.com/@zedstatistics"}> zedstatistics</ELink>.<br/>
            </p>

            <p>
            Článek o <ELink href={"https://www.cs.uic.edu/~wilkinson/Publications/stevens.pdf"}>typech statistických znaků</ELink>.<br/>
            </p>

            <p>
            Nebojte se hojně využívat Wikipedii, YouTube, AI (ChatGPT, ollama) a další zdroje.
            </p>

            <h1>Software pro statistické výpočty</h1>

            Programovací jazyk <ELink href='https://www.r-project.org/'>R</ELink> a
            jeho rozhraní <ELink href='https://posit.co/download/rstudio-desktop/'>RStudio</ELink>.<br/>

            Programovací jazyk <ELink href='https://www.python.org/'>Python</ELink>
            &nbsp;a balíky <ELink href='https://numpy.org/'>NumPy</ELink>,&nbsp;
            <ELink href='https://scipy.org/'>SciPy</ELink>
            &nbsp;a <ELink href='https://pandas.pydata.org/'>Pandas</ELink>.<br/>
            Octave, Matlab, Maple.<br/>
            OpenOffice, LibreOffice, MS Excel.<br/>

            {/* Malý výlet do moderní matematiky ~ 1972 */}
            {/* https://dml.cz/handle/10338.dmlcz/403752 */}

            {/* O náhodě a pravděpodobnosti ~ 1982*/}
            {/* https://dml.cz/handle/10338.dmlcz/404026 */}


            <h1>Klasická literatura - reference</h1>

            <p>ANDĚL, Jiří. <i>Statistické metody.</i> 5. vyd. Praha: MatfyzPress, 2019. 300 s. ISBN 978-80-7378-381-5.</p>
            <p>ANDĚL, Jiří. <i>Základy matematické statistiky.</i> 3. vyd. Praha: MatfyzPress, 2011. 360 s. ISBN  978-80-7378-162-0.</p>
            <p>ZVÁRA, Karel, ŠTĚPÁN Josef. <i>Pravděpodobnost a matematická statistika.</i> 6. vyd. Praha: MatfyzPress, 2019. 232 s. ISBN 978-80-7378-388-4.</p>
            <p>MELOUN, Milan, MILITKÝ, Jiří. <i>Kompendium statistického zpracování dat.</i> 2. vyd. Academia, 2006.</p>
            <p>RENYI, Alfred. <i>Probability Theory.</i> Dover Books on Mathematics, Dover Publications, 2007. 672 s. ISBN 978-0486458670.</p>
            <p>BAUER, Heinz. <i>Probability Theory and Elements of Measure Theory.</i> Academic Press, 1981. ISBN 0-12-082820-0.</p>

        </div>
        </div>
        </div>
    );
}
