import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';


export const DescriptiveStatisticsMeta = {
    title: "Popisná statistika",
    shortTitle: "Popisná statistika",
    path: Path.descriptive_statistics_intro,
    element: (sectionNumber:string) => <DescriptiveStatistics sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function DescriptiveStatistics({sectionNumber}: {sectionNumber: string}) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={DescriptiveStatisticsMeta.title}>

            <p>
            Popisná statistika je statistika na datech.
            Různé zobrazovací techniky (např. histogram) a výpočetní vzorce (např. vzorec pro průměr a rozptyl)
            nám umožňují udělat si představu o povaze dat.
            Na rozdíl od matematické statistiky (inference) zde ještě není přesah v tom smyslu, že
            bychom za daty viděli nějaký pravděpodobnostní model, testovali hypotézy nebo prováděli intervalové odhady.

            Koncepty popisné statistiky se ale ve stejné podobě objevují později i v pravděpodobnosti a inferenci -
            proto jsou ideální náplní na úplný začátek kurzu.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

