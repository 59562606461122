import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';


// import mnoziny from '../assets/images/mnoziny.png';
const mnoziny = require('../assets/images/mnoziny.png');
const mnoziny_zaklad = require('../assets/images/mnoziny_zaklad.png');
const a_minus_b = require('../assets/images/a_minus_b.png');
const a_complement = require('../assets/images/a_complement.png');
const symdiff = require('../assets/images/symdiff.png');
const symdiff3 = require('../assets/images/symdiff3.png');


export const SetsMeta = {
    title: "Množiny (2)",
    shortTitle: "Množiny (2)",
    path: Path.sets,
    element: (sectionNumber:string) => <Sets sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "sets-difference",
    "sets-complement",
    "sets-symdiff",
    "sets-demorgan",
    "sets-all-ops",
    "sets-logical-ops",
];


export function Sets({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SetsMeta.title} TOCSpec={TOCSpec}>

        <p>
        Než se pustíme do práce s jevy a jevovým polem, tato sekce nám připomene množinové operace.
        Tabulka na konci připomíná známou souvislost logických a množinových operací.
        Např. máme-li množinu {"$A$"} prvků, které mají vlastnost {"$x$"},
        množinu {"$B$"} prvků, které mají vlastnost {"$y$"}, pak množina prvků,
        které mají vlastnost {"$x$"} <em>a</em> {"$y$"} (logická spojka "a", symbol {"$\\land$"})
        je průnikem množin {"$A$"} a {"$B$"}, tj. {"$A \\cap B$"} (symbol {"$\\cap$"}).
        Podobnost symbolů {"$\\land$"} a {"$\\cap$"} není náhodná - vyjadřují stejnou věc,
        jednou ve výrokové logice, podruhé v množinovém zápisu.
        </p>

        <p>
        Dále zopakujeme pojem <em>doplněk</em>, množinový <em>rozdíl</em> a <em>symetrická diference</em>&nbsp;
        (která je někdy opomíjena ve středoškolské matematice, ale jak uvidíte na obrázku
        níže, zapadá přirozeně do základního souboru množinových operací).
        </p>


        <h2 id="sets-difference">Rozdíl</h2>
        <p>
        Rozdíl {"$A \\setminus B$"} je definován jako množina
        všech prvků v {"$A$"}, které zároveň nejsou prvky {"$B$"}.
        Tj.
        {`$$
            A \\setminus B = \\{x: x \\in A \\land x \\notin B\\}.
        $$`}
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={a_minus_b} style={{width: 300}}/>
        </div>

        <h2 id="sets-complement">Doplněk</h2>
        <p>
        V kontextu pravděpodobnosti vždy pracujeme s podmnožinami množiny {"$\\Omega $"} elementárních jevů.
        Doplněk (komplement) množiny {"$A$"} v {"$\\Omega $"} je definován jako
        {`$$
            A^\\complement = \\Omega \\setminus A.
        $$`}
        Doplněk značíme buď {"$\\overline{A}$"} nebo {"$A^\\complement$"},
        dle potřeby v daném kontextu (např. abychom si ušetřili závorky).
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={a_complement} style={{width: 110}}/>
        </div>
        <p>
        Ověřte si, že platí:
        </p>

        <ul>
            <li>{"$A \\setminus B = A \\setminus (A\\cap B)$"}.</li>
            <li>{"$A \\setminus B = A \\cap B^\\complement$"}.</li>
            {/* <li>{"$A^\\complement = \\Omega \\setminus A$"}.</li> */}
            <li>{"$A \\setminus \\emptyset = A$, $A \\setminus \\Omega = \\emptyset$"}.</li>
            <li>{"$\\left(A^\\complement\\right)^\\complement = A$"}.</li>
            <li>Je-li {"$A^\\complement = B$"}, potom {"$B^\\complement = A$"}.</li>
        </ul>


        <h2 id="sets-symdiff">Symetrická diference</h2>
        <p>
        Symetrická diference {"$A \\triangle B$"} je definována jako
        {`$$
            A \\triangle B = (A \\setminus B) \\cup (B \\setminus A) = \\{x: x \\in A \\setminus B \\lor x \\in B \\setminus A \\}.
        $$`}
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={symdiff} style={{width: 130}}/>
        </div>
        <p>
        Platí (ověřte si):
        </p>
        <ul>
            <li>{"$A \\triangle B = B \\triangle A$"}.</li>
            <li>{"$A \\triangle (B \\triangle C) = (A \\triangle B) \\triangle C$"}.</li>
            <li> Je-li {"$B \\subset A$"}, potom {"$A \\triangle B = A \\setminus B$"}.</li>
            <li>{"$A \\triangle \\Omega = \\Omega \\setminus A = A^\\complement$"}.</li>
            <li>{"$A \\triangle \\emptyset = A \\setminus \\emptyset = A$"}.</li>
        </ul>
        <p>Symetrická diference se chová asociativně, proto ve výrazu {"$A \\triangle B \\triangle C$"} nemusíme závorkovat - výsledek
        je stejný ať už operace provedeme v libovolném pořadí. I graficky je tato symetrie vidět:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={symdiff3} style={{width: 100}}/>
        </div>


        <h2 id="sets-demorgan">De Morganovy zákony</h2>

        <p>
        Doplněk "konvertuje" sjednocení na průnik, a naopak. Doplněk sjednocení je průnik doplňků; doplněk průniku je sjednocení doplňků:
        </p>

        <p>
        {"$$" +
        "\\begin{align*}" +
        "\\left(A \\cup B\\right)^\\complement & = A^\\complement \\cap B^\\complement   & \\left(A \\cap B\\right)^\\complement & = A^\\complement \\cup B^\\complement  \\\\ " +
        "\\Big( \\bigcup_{i=1..n} A_i \\Big)^\\complement & = \\bigcap_{i=1..n} A_i^\\complement & \\Big( \\bigcap_{i=1..n} A_i \\Big)^\\complement & = \\bigcup_{i=1..n} A_i^\\complement  \\\\ " +
        "\\Big( \\bigcup_{i \\in I} A_i \\Big)^\\complement & = \\bigcap_{i \\in I} A_i^\\complement & \\Big( \\bigcap_{i \\in I} A_i \\Big)^\\complement & = \\bigcup_{i \\in I} A_i^\\complement " +
        "\\end{align*}" +
        "$$"}
        </p>

        De Morganovy zákony platí pro libovolný počet množin - pro dvě (první řádek), pro {"$n$"} (druhý řádek)
        a pro nekonečně mnoho množin (třetí řádek - kde indexová množina {"$I$"} může být spočetná nebo nespočetná).

        {/* <table>
            <thead>
                <tr>
                    <td>Počet množin</td>
                    <td></td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>2</td>

                </tr>
            </tbody>
        </table> */}

        <h2 id="sets-all-ops">Všechny operace na dvou množinách - srovnání</h2>

        <p>
        Obrázky níže ukazují všech 16 množin, které můžeme získat ze dvou podmnožin
        &nbsp;{"$A$, $B$"} množiny {"$\\Omega$"}.
        Jednotlivé "segmenty" jsou barevně odlišeny a označeny písmeny a, b, x, o.
        Vzhledem k tomu, že máme 4 segmenty, lze z nich složit {"$2^4 = 16$"} výsledků.
        (U každého segmentu máme 2 možnosti - buď ve výsledku bude, nebo ne - dohromady
        je to {"$2 \\times 2 \\times 2 \\times 2$"} možností.) Na každém řádku platí,
        že množina v pravém sloupci je komplementem množiny v levém sloupci (a naopak).
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mnoziny_zaklad} style={{width: 300}}/>
        </div>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={mnoziny}/>
        </div>

        <h2 id="sets-logical-ops">Souvislost logických a množinových operací</h2>

        <p>Tabulka níže ukazuje souvislost mezi množinovými operacemi a logickými operacemi. Pokud je vám to jasné,
        nemusíte ani číst dál. Pokud ne, další text vysvětluje princip.
        </p>

        <table className='simple-table simple-table-ops' style={{fontSize: 12}}>
            {/* <caption>Legenda: m.o.: množinová operace, l.o.: logická operace.</caption> */}
            <thead>
                <tr>
                    <th>množinová operace</th>
                    <th>symbol</th>
                    <th>logická operace</th>
                    <th>symbol</th>
                    <th colSpan={2}>množina nebo tvrzení</th>
                    {/* <th></th> */}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>průnik</td>
                    <td>{"$\\cap$"}</td>
                    <td>konjunkce ('a')</td>
                    <td>{"$\\land$"}</td>
                    <td>{"$A \\cap B$"}</td>
                    <td>{"$= \\{x: x \\in A \\land x \\in B\\}$"}</td>
                </tr>
                <tr>
                    <td>sjednocení</td>
                    <td>{"$\\cup$"}</td>
                    <td>disjunkce ('nebo')</td>
                    <td>{"$\\lor$"}</td>
                    <td>{"$A \\cup B$"}</td>
                    <td>{"$= \\{x: x \\in A \\lor x \\in B\\}$"}</td>
                </tr>
                <tr>
                    <td>doplněk</td>
                    {/* <td>{"$\\bar{\\ }$"} nebo {"$^\\complement$"}</td> */}
                    <td>{"$\\bar{\\ }$"}</td>
                    <td>negace ('ne')</td>
                    <td>{"$\\neg$"}</td>
                    {/* <td>{"$\\overline{A}$"} nebo {"$A^\\complement$"}</td> */}
                    <td>{"$\\overline{A}$"}</td>
                    <td>
                    {"$= \\{x: x \\in \\Omega \\land \\neg (x \\in A) \\} = \\{x: x \\in \\Omega \\land x \\notin A \\}$"}
                    </td>
                </tr>
                <tr>
                    <td>podmnožina</td>
                    <td>{"$\\subset $"}</td>
                    <td>implikace</td>
                    <td>{"$\\Rightarrow$"}</td>
                    <td>{"$A \\subset B$"}</td>
                    <td>{"$\\Leftrightarrow \\ \\forall x (x \\in A \\Rightarrow x \\in B) \\}$"}</td>
                </tr>
            </tbody>
        </table>

        {/* <p>Pozn.: Abychom si nemuseli dělat starost s existencí množin a platností našich tvrzení (viz https://cs.wikipedia.org/wiki/Axiomatick%C3%A1_teorie_mno%C5%BEin ),
            které jsou  */}
        <p>Pohybujeme se v nějaké množině {"$\\Omega$"} (univerzu), která obsahuje všechny prvky, o kterých mluvíme. Řekněme např., že {"$\\Omega = \\mathbb{N}$"}.
        Uvažujeme-li nějakou <strong>vlastnost</strong> (např. sudost), potom této vlastnosti odpovídá množina prvků, které tuto vlastnost mají:
        {`$$
            S = \\{x: \\text{je_sudé}(x)\\}.
        $$`}
        Funkce {"$\\text{je_sudé}$"} může být implementována např. jako {"$x \\mapsto (x \\mod 2 = 0)$"}. Je-li to pro dané {"$x$"} pravda (rovnice platí),
        pak {"$x$"} má sledovanou vlastnost (sudost) a patří do množiny {"$S$"}. Je-li to pro dané {"$x$"} nepravda, pak {"$x$"} nemá sledovanou vlastnost (sudost)
        a do množiny {"$S$"} nepatří.
        </p>
        <p>
        Obecně, uvažujeme-li nějakou vlastnost, pak množina všech prvků, které tuto vlastnost mají, je
        {`$$
            V = \\{x: \\text{má_sledovanou_vlastnost}(x)\\}.
        $$`}
        Funkci {"$\\text{má_sledovanou_vlasntost}$"} se říká <strong>predikát</strong> - je to funkce, která testuje onu vlastnost na každém prvku.
        Predikát musí být aplikovatelný na každý prvek z {"$\\Omega$"}, a musí vždy dát výsledek pravda (true) nebo nepravda (false).
        Např. {"$\\text{je_sudé}(2) = \\text{true}$"}, {"$\\text{je_sudé}(3) = \\text{false}$"}.
        </p>
        <p>
        Uvažujme teď dvě různé vlastnosti současně: vlastnost {"$a$"} a vlastnost {"$b$"}.<br/>
        Množina prvků, které mají vlastnost {"$a$"}:
        {`$$
            A = \\{x: \\text{má_vlastnost_a}(x)\\}
        $$`}
        Množina prvků, které mají vlastnost {"$b$"}:
        {`$$
            B = \\{x: \\text{má_vlastnost_b}(x)\\}
        $$`}
        Jak vypadá množina prvků, které mají obě vlastnosti současně, tj. mají vlastnost {"$a$"} <span style={{color: 'red'}}>a</span> {"$b$"}?
        Tyto prvky musí mít jak vlastnost {"$a$"} - tj. musí patřit do množiny {"$A$"} (prvky s vlastností {"$a$"}),
        tak současně musí mít vlastnost {"$b$"} - tj. musí patřit do množiny {"$B$"} (prvky s vlastností {"$b$"}).
        Tyto prvky tedy patří do množin {"$A$"} i {"$B$"} - tj. patří do průniku {"$A \\cap B$"}:
        {`$$
            \\{x: \\text{má_vlastnost_a}(x) \\land \\text{má_vlastnost_b}(x) \\} = \\{x: x \\in A \\land x \\in B\\} = A \\cap B.
        $$`}
        Vidíme, že logická spojka "a" (konjunkce, symbol {"$\\land$"}) odpovídá množinové operaci "průnik" (symbol {"$\\cap$"}).
        </p>
        <p>
        Např., pokud vlastnost {"$a$"} je sudost a vlastnost {"$b$"} je dělitelnost třemi, pak množina prvků, které jsou současně sudé a dělitelné 3, je
        průnikem množiny sudých čísel a množiny čísel dělitelných 3:
        </p>
        <p style={{fontSize: 13}}>
        {`$$\\begin{align*}
            A &= \\{x: x \\text{ je sudé}\\}, & B &= \\{x: x \\text{ je dělitelné 3}\\}, & A \\cap B &= \\{x: x \\text{ je sudé a dělitelné 3}\\} \\\\
            A &= \\{x: x \\text{ mod } 2 = 0\\}, & B &= \\{x: x \\text{ mod } 3 = 0\\}, & A \\cap B &= \\{x: (x \\text{ mod } 2 = 0) \\land (x \\text{ mod } 3 = 0) \\}
        \\end{align*}$$`}
        </p>

        <p>
        Analogicky si nyní můžete odargumentovat další případy: sjednocení, doplněk,...
        </p>

        <hr/>
        <p>Poznámka pro pokročilejší: Obecně samozřejmě nelze předpokládat existenci množiny pro libovolnou vlastnost - např. slavný zápis {"$X = \\{x: x \\notin x\\}$"}&nbsp;
        vede k tzv.&nbsp;
        <ELink href={"https://cs.wikipedia.org/wiki/Russell%C5%AFv_paradox" }>Russelovu paradoxu</ELink>:&nbsp;
        {"$X \\in X \\Rightarrow X \\notin X$"} a zároveň {"$X \\notin X \\Rightarrow X \\in X$"}.
        Vyřešení tohoto paradoxu poskytla až&nbsp;
        <ELink href={"https://cs.wikipedia.org/wiki/Axiomatick%C3%A1_teorie_mno%C5%BEin" }>axiomatická teorie množin</ELink>,
        která definuje, jaké množiny můžeme vytvářet (existují), a jak s nimi pracovat.
        Pokud se ale pohybujeme již v rámci existující množiny, potom konstrukce množin podle vlastností je zcela legitimní.
        Na začátku jsme si řekli, že pracujeme v nějakém univerzu {"$\\Omega$"}. Každý zápis v této části se tedy musí interpretovat s implicitní podmínkou &nbsp;
        {"$x \\in \\Omega$"}. Např. zápis
        {`$$
            A = \\{x: \\text{má_sledovanou_vlastnost}(x)\\\}
        $$`}
        je třeba chápat jako
        {`$$
            A = \\{x: x \\in \\Omega \\land \\text{má_sledovanou_vlastnost}(x)\\}.
        $$`}

        </p>

        <Footer/>
        </ChapterWrapper>
    );
}

