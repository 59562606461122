import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "./MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


interface Term {
    en: string;
    cz: string;
    note?: string;
}

const generalTerms: Term[] = [
    {
        en:"expected value",
        cz:"střední hodnota",
    },
    {
        en:"variance",
        cz:"rozptyl",
        note: "značení: en: Var(x), cz: D(X) /D ~ 'disperze'/"
    },
    {
        en:"standard deviation",
        cz:"směrodatná odchylka",
    },
    {
        en:"sample",
        cz:"statistický soubor; výběr"
    },
    {
        en:"(statistical) variable",
        cz:"znak, statistický znak",
    },
    {
        en:"population",
        cz:"populace, základní soubor",
    },
    {
        en:"probability",
        cz:"pravděpodobnost",
    },
    {
        en:"distribution",
        cz:"rozdělení",
    },
    {
        en:"mode",
        cz:"modus",
    },
    {
        en:"quantile",
        cz:"kvantil",
    },
    {
        en:"quartile",
        cz:"kvartil",
    },
    {
        en:"percentile",
        cz:"percentil",
    },
    {
        en:"mean",
        cz:"průměr",
    },
    {
        en:"skewness",
        cz:"šikmost, koeficient šikmosti",
    },
    {
        en:"kurtosis",
        cz:"špičatost, koeficient špičatosti",
    },
    {
        en:"density (probability density function)",
        cz:"hustota pravděpodobnosti",
        note: "angl. zkratka 'pdf'"
    },
    {
        en:"probability mass function",
        cz:"pravděpodobnostní funkce",
    },
    {
        en: "cumulative distribution function",
        cz: "distribuční funkce",
        note: "angl. zkratka 'cdf'",
    },
    {
        en:"random variable",
        cz:"náhodná veličina",
    },
    {
        en:"random vector",
        cz:"náhodný vektor",
    },
    {
        en:"marginal",
        cz:"marginální",
    },
    {
        en:"countable",
        cz:"spočetný",
    },
    {
        en:"uncountable",
        cz:"nespočetný",
    },
    {
        en:"sample mean",
        cz:"výběrový průměr",
    },
    {
        en:"iid",
        cz:"i.i.d.",
        note: "independent identically distributed (random variables) - nezávislé stejně rozdělené (náhodné veličiny)",
    },
    {
        en:"elementary event, atomic event, outcome",
        cz:"elementární jev",
    },
    {
        en:"event",
        cz:"jev, náhodný jev",
    },
    {
        en:"sample space, outcome space",
        cz:"prostor elementárních jevů",
    },
    {
        en:"powerset",
        cz:"množina všech podmnožin, potenční množina",
    },
    {
        en:"complementary event",
        cz:"opačný jev",
        note: "množinový doplněk (komplement)",
    },
    {
        en:"mutually exclusive, disjoint",
        cz:"disjunktní",
    },
]
generalTerms.sort((a, b) => a.cz.localeCompare(b.cz));


const dicreteDistributionTerms: Term[] = [
    {
        en: "uniform",
        cz: "rovnoměrné",
    },
    {
        en: "binomial",
        cz: "binomické",
    },
    {
        en: "Bernoulli ",
        cz: "alternativní, Bernoulliho",
    },
    {
        en: "geometric",
        cz: "geometrické",
    },
    {
        en: "hypergeometric",
        cz: "hypergeometrické",
    },
    {
        en: "Poisson [ˈpwɑːsɒn]",
        cz: "Poissonovo [poasonovo]",
    },
    {
        en: "negative binomial",
        cz: "negativně binomické",
    },
    {
        en: "logarithmic",
        cz: "logaritmické",
    },
]


const continuousDistributionTerms: Term[] = [
    {
        en: "uniform",
        cz: "rovnoměrné",
    },
    {
        en: "normal",
        cz: "normální, Gaussovo",
        note: "bell curve"
    },
    {
        en: "log-normal",
        cz: "log-normální",
    },
    {
        en: "gamma",
        cz: "gama",
    },
    {
        en: "Student's t-distribution",
        cz: "Studentovo t-rozdělení",
    },
    {
        en: "Cauchy",
        cz: "Cauchyho",
        note: "heavy/fat tails"
    },
    {
        en: "chi-squared",
        cz: "chí-kvadrát, Pearsonovo",
    },
]


export function DictionaryTable({terms, title, id}: {terms: Term[], title: string, id: string}) {
    return (
        <>
        <h2 id={id}>{title}</h2>
        <table className="data-table table-dictionary">
            <thead>
                <tr>
                    <th>CZ</th>
                    <th>EN</th>
                    <th>Poznámka</th>
                </tr>
            </thead>
            <tbody>
                {terms.map((term, i) => (
                    <tr key={term.cz}>
                        <td>{term.cz}</td>
                        <td>{term.en}</td>
                        <td>{term.note}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    );
}

const TOCSpec = [
    "general-terms",
    "discrete-distributions",
    "continuous-distributions",
]

export function Dictionary() {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

            <p>Pro referenci uvádíme překlad některých pojmů mezi angličtinou a češtinou.</p>

            <DictionaryTable terms={generalTerms} title="Obecné pojmy" id="general-terms"/>
            <DictionaryTable terms={dicreteDistributionTerms} title="Diskrétní rozdělení" id="discrete-distributions"/>
            <DictionaryTable terms={continuousDistributionTerms} title="Spojitá rozdělení" id="continuous-distributions"/>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}
