import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { ELink } from '~/ExternalLink';


export const HypothesisTestingMeta = {
    title: "Testy hypotéz",
    shortTitle: "Testy hypotéz",
    path: Path.hypothesis_testing,
    element: (sectionNumber:string) => <HypothesisTesting sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "summary",
];


export function HypothesisTesting({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={HypothesisTestingMeta.title} TOCSpec={TOCSpec}>

            {/* <h2 id="summary">Shrnutí</h2> */}

            <p><i>Připravujeme...</i></p>

            Viz přednášky (poznámky, videa), a nebo např.

            &nbsp;<ELink href={"https://www.youtube.com/watch?v=8JIe_cz6qGA"}>youtube</ELink>,

            nebo

            &nbsp;<ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_2/pages/05-test-statist-hypotez.html"}>kurz MUNI</ELink>

            &nbsp;(matematičtější), nebo

            &nbsp;<ELink href={"https://www.stat.berkeley.edu/~stark/SticiGui/Text/testing.htm"}>Stark</ELink>.

            <Footer/>
        </ChapterWrapper>
    );
}

