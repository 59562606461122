import React  from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ELink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

const jevove_pole_1 = require('../assets/images/jevove_pole_1.png');
const jevove_pole_2 = require('../assets/images/jevove_pole_2.png');
const jevove_pole_3 = require('../assets/images/jevove_pole_3.png');
const jevove_pole_4 = require('../assets/images/jevove_pole_4.png');

const jevove_pole_aprox = require('../assets/images/jevove_pole_aprox.png');

const jevove_pole_krizky_1 = require('../assets/images/jevove_pole_krizky_1.png');
const jevove_pole_krizky_2 = require('../assets/images/jevove_pole_krizky_2.png');
const jevove_pole_krizky_3 = require('../assets/images/jevove_pole_krizky_3.png');

const jevove_pole_additive = require('../assets/images/jevove_pole_additive.png');
const jevove_pole_complement = require('../assets/images/jevove_pole_complement.png');

const jevove_pole_limit = require('../assets/images/jevove_pole_limit.png');


export const EventSpaceMeta = {
    title: "Jevové pole",
    shortTitle: "Jevové pole",
    path: Path.event_space,
    element: (sectionNumber:string) => <EventSpace sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

type Subset = number[];
type Distribution = { [key: string]: number };

function getAllSubsets(set: number[]): Subset[] {
    const subsets: Subset[] = [];
    const total = 1 << set.length; // 2^n subsets

    for (let i = 0; i < total; i++) {
        const subset: Subset = [];
        for (let j = 0; j < set.length; j++) {
        if (i & (1 << j)) {
            subset.push(set[j]);
        }
        }
        subsets.push(subset);
    }

    return subsets;
}


function sumOfP(subset: Subset, p: { [key: number]: number}): number {
    return subset.reduce((sum, value) => sum + p[value], 0);
}

function materializeDistribution(p: { [key: number]: number }): Distribution {
    const subsets = getAllSubsets(Object.keys(p).map(Number));
    const distribution: Distribution = {};

    for (const subset of subsets) {
        const key = subset.join(',');
        distribution[key] = sumOfP(subset, p);
    }

    return distribution;
}

const p: { [key: number]: number } = {
    1: 0.1,
    2: 0.15,
    3: 0.15,
    4: 0.15,
    5: 0.15,
    6: 0.3
};

const set = [1, 2, 3, 4, 5, 6];

// will be lex ordered (?)
const distributionDie = materializeDistribution(p);

// const tableSpec = `
//     "";             1,2;    3,6;    6,4;
//     1,2,3,4,5,6;    1,3;    4,1;    6,5;
//     1;              1,4;    4,2;    6,6;
//     2;              1,5;    4,3;
//     3;              1,6;    4,5;
//     4;              2,1;    4,6;
//     5;              2,3;    5,1;
//     6;              2,4;    5,2;
//           2,5;    5,3;
//           2,6;    5,4;
//           3,1;    5,6;
//           3,2;    6,1;
//           3,4;    6,2;
//           3,5;    6,3;
// `;

// // 2,3,4,5,6;
// // 1,3,4,5,6;
// // 1,2,4,5,6;
// // 1,2,3,5,6;
// // 1,2,3,4,6;
// // 1,2,3,4,5;

const keys = Object.keys(distributionDie).sort();
const chunkSize = 16;
const chunks: string[][] = [];
for (let i = 0; i < keys.length; i += chunkSize) {
  chunks.push(keys.slice(i, i + chunkSize));
}

const TOCSpec = [
    "evspace-intro-discrete",
    "evspace-intro-continuous",
    "evspace-advice",
    "evspace-example-points",
    "evspace-properties",
];

export function EventSpace({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={EventSpaceMeta.title} TOCSpec={TOCSpec}>

            <p><i>
            Pozn.: Jevové pole a pravděpodobnost jsou spojené koncepty: Pravděpodobnost
            je funkce definovaná na jevovém poli (každému jevu přiřazuje číslo - jeho pravděpodobnost),
            a zároveň (někdy) je jevové pole definováno jako největší množina jevů, na kterém lze
            pravděpodobnost (určitým způsobem) definovat.
            Při vysvětlování je to tedy trochu případ "vejce nebo slepice" - nejde vysvětlit jeden pojem,
            aniž by se aspoň trochu zmínil druhý.
            </i></p>

            <h2 id="evspace-intro-discrete">Úvod - diskrétní příklad</h2>

            <p>
            V minulé kapitole jsme definovali náhodný jev jako podmnožinu {"$\\Omega$"} (množiny elementárních jevů).
            Například při hodu kostkou je {"$\\Omega = \\{1,2,3,4,5,6\\}$"} a jev "padne liché číslo" je množina {"$A = \\{1,3,5\\} \\subset \\Omega$"}.
            </p>

            <p>
            Při hodu kostkou dokážeme identifikovat {"$2^6 = 64$"} různých podmnožin množiny {"$\\Omega$"} - tedy 64 různých jevů.
            Pokud je kostka férová, tak můžeme definovat pravděpodobnost pro každý z těchto jevů předpisem
            {`$$
                P(A) = \\frac{|A|}{6},
            $$`}
            kde {"$|A|$"} značí velikost množiny {"$A$"}. Například pro jev "padne liché číslo" je
            pravděpodobnost {"$P(A) = P(\\{1,3,5\\}) = \\frac{3}{6} = \\frac{1}{2}$"}.
            (Tři z šesti čísel jsou lichá, takže pravděpodobnost jevu "padne liché číslo" je {"$\\frac{3}{6}$"}.)
            Pravděpodobnost jevu "padla šestka" je {"$P(\\{6\\}) = \\frac{1}{6}$"}, a pravděpodobnost jevu "padlo libovolné číslo" je {"$P(\\Omega) = \\frac{6}{6} = 1$"}.
            Vidíme, že se tato konstrukce chová smysluplně.
            </p>

            <p>Pokud by kostka férová nebyla (např. by byla zmagnetizovaná, a házeli bychom na zmagnetizovanou podložku), potom by matematický model vypadal takto:</p>

            <p>1) Zvolíme si pomocná čísla {"$p_1, \\ldots, p_6$"} taková, že jsou nezáporná a jejich součet je 1. Například:</p>

            <table className="simple-table">
                <tbody>
                    <tr>
                        <td>{"$p_1$"}</td>
                        <td>{"$p_2$"}</td>
                        <td>{"$p_3$"}</td>
                        <td>{"$p_4$"}</td>
                        <td>{"$p_5$"}</td>
                        <td>{"$p_6$"}</td>
                    </tr>
                    <tr>
                        <td>0.10</td>
                        <td>0.15</td>
                        <td>0.15</td>
                        <td>0.15</td>
                        <td>0.15</td>
                        <td>0.30</td>
                    </tr>
                </tbody>
            </table>

            <p>Tento model odpovídá tomu, že kostka jedním pólem dopadá dolů nejčastěji (na horní kostky straně je v tom případě číslo 6),
            opačným pólem dopadá nejméně často (na horní straně je číslo 1), a ostatní stěny (čísla) padají stejně často.
            </p>

            <p>2) Pravděpodobnost libovolného jevu {"$A$"} definujeme jako
            {`$$
                P(A) = \\sum \\big\\{ p_i \\mid i \\in A \\big\\} = \\sum_{i \\in A} p_i.
            $$`}
            Například pravděpodobnost jevu "padne liché číslo" je
            {`$$
                P(A) = P(\\{1, 3, 5\\}) = p_1 + p_3 + p_5 = 0.10 + 0.15 + 0.15 = 0.40.
            $$`}
            Tento mechanismus přiřazení pravděpodobností jevům (ohodnocení jevů) je ilustrován níže ve čtyřech krocích:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_1} style={{width: "25%"}}/>
            </div>

            <p>Pravděpodobnost <i>všech</i> jevů vypadá takto:</p>

            <table className='simple-table simple-table-basic event-space-table-die'>
                <thead>
                    <tr>
                    <th>A</th>
                    <th>P(A)</th>
                    <th>A</th>
                    <th>P(A)</th>
                    <th>A</th>
                    <th>P(A)</th>
                    <th>A</th>
                    <th>P(A)</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: chunkSize }).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {chunks.map((chunk, chunkIndex) => (
                        <>
                            <td key={`key-${chunkIndex}-${rowIndex}`}>
                            {'{' + chunk[rowIndex] + '}' || '{}'}
                            </td>
                            <td key={`value-${chunkIndex}-${rowIndex}`}>
                            {chunk[rowIndex] ? distributionDie[chunk[rowIndex]].toFixed(2) : ''}
                            </td>
                        </>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>

            <p>
            Jevové pole je soubor všech množin, které se vyskytují ve sloupcích nadepsaných 'A' v této tabulce.
            </p>


            <h2 id="evspace-intro-continuous">Úvod - spojitý příklad</h2>

            <p>Máme čtvercový detektor/čip o straně 1cm, a zachycujeme jím fotony, a zaznamenáváme si polohy dopadu.</p>
            <p>
            Naše elementární jevy jsou polohy dopadu fotonu, tedy body {"$(x, y)$"} v rovině, kde {"$x, y \\in \\langle 0, 1 \\rangle$"}.
            Množina všech elementárních jevů je čtverec v rovině {"$\\Omega = \\langle 0, 1 \\rangle \\times \\langle 0, 1 \\rangle$"}.
            </p>

            <p>Jak mohou vypadat náhodné jevy? Jednotlivé jevy jsou podmnožiny {"$\\Omega$"}, tedy podoblasti daného čtverce.
            Například jev "foton dopadne do horní poloviny čipu" je obdélník {"$A = \\langle 0, 1 \\rangle \\times \\langle 0.5, 1.0 \\rangle$"}.
            Jev "foton dopadne do levé poloviny čipu" je obdélník {"$B = \\langle 0, 0.5 \\rangle \\times \\langle 0, 1 \\rangle$"}.
            </p>

            <p>Dejme tomu, že pozorujeme (nebo máme důvod předpokládat),
            že fotony na čip dopadají naprosto náhodně a 'homogenně',
            tj. v žádné oblasti - např. ani v jednom rohu, ani ve středu - v principu
            nedopadají s větší frekvencí / hustotou než jinde.
            </p>

            <p>Jak bychom jevům přiřadili pravděpodobnosti? V našem modelu bychom například chtěli,
            aby pravděpodobnost, že foton dopadne do horní poloviny čipu, byla {"$\\frac{1}{2}$"}.
            A taky aby pravděpodobnost, že foton dopadne do levé poloviny čipu, byla {"$\\frac{1}{2}$"}.
            Nabízí se, že pravděpodobnost jevu {"$A$"} bude rovna velikosti plochy oblasti {"$A$"}:
            {`$$
                P(A) = \\mathrm{Plocha}(A).
            $$`}
            Obrázek níže ukazuje tento princip pro jev, že foton dopadne do žlutě vyznačené oblasti:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_2} style={{width: "50%"}}/>
            </div>

            <p>
            Jak vypadá jevové pole? Chtělo by se říct, že to bude libovolná
            podmnožina/podoblast čtverce {"$\\Omega$"}.<br/>
            <strong>Jsme ale schopni určit plochu libovolné podmnožiny?</strong> Například takovéto?:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_3} style={{width: "31%"}}/>
            </div>

            <p>
            Na první pohled se může zdát, že odpověď je ano - umíme použít vzorce pro obsah
            trojúhelníku, polygonu, kruhu, ... a když je oblast nějak obecně křivá, tak do ní vepíšeme
            (nebo kolem ní opíšeme) posloupnost tvarů, které ji budou přesněji a přesněji aproximovat,
            a za velikost její plochy vezmeme limitu velikostí ploch těchto tvarů...:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_aprox} style={{width: "55%"}}/>
            </div>

            <p>
            Problém je v tom, že odpověď je ve skutečnosti <strong>ne</strong>.
            A není to tím, že bychom nebyli dostatečně kreativní a ještě jsme nevymysleli metodu,
            jak to udělat; dá se ukázat, že to nejde <i>v principu</i> (to už je ale poměrně pokročilá matematika).
            </p>
            <p>
            Pokud máme dvě množiny, které mají stejný tvar, ale liší se jen tím, že jsou vzájemně posunuté,
            tak přirozeně očekáváme, že velikost jejich plochy by měla být stejná.
            (Toto mj. evidentně platí pro trojúhelníky, obdélníky, kruhy, ...)
            </p>
            <p>
            Různých podmnožin reálných čísel je ale opravdu hodně, a mnohé jsou <i>velice</i> zvláštní. Představme
            si například, že budeme určovat velikost plochy množiny, která se
            nazývá <ELink href={"https://cs.wikipedia.org/wiki/Sierpi%C5%84sk%C3%A9ho_koberec"}>Sierpińského koberec</ELink>:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_4} style={{width: "35%"}}/>
            </div>

            <p>Tato množina ale ještě není dost divná; dá se rozumně odvodit, že její plocha je 0.
            Některé množiny jsou řádově divnější.
            A to natolik, že se ukazuje, že ať bychom jim jako velikost jejich plochy přiřadili jakékoli číslo,
            tak by to vždy vedlo ke sporu. Zhruba řečeno, zkopírování spočetně nekonečného množství
            posunutých replik takové divné množiny by nám (bez překryvů) přesně vyplnilo ('vydlaždičkovalo')
            celý čtverec.
            </p>
            <p>
            Kdyby plocha takové množiny ('dlaždičky') byla nenulová, pak by plocha celého čtverce
            - jako součet velikostí ploch všech těchto 'dlaždiček' -
            vyšla nekonečno, protože nekonečný součet stejných čísel je nekonečno.
            Kdyby plocha takové množiny ('dlaždičky') byla nulová, pak by plocha
            čtverce vyšla také nulová, protože spočetný součet nul je nula.
            Velikost plochy takové množiny tedy nemůže být ani nula, ani nenulové číslo -
            plocha celého čtverce jako součet dílčích ploch by nám jednou vyšla nekonečno, a podruhé nula ...
            ale ani jednou 1. Takové množině tedy v principu nemůžeme přiřadit velikost plochy.
            (Pozn.: Autor aktuálně neví, jak takovou množinu vizualizovat - takže to zatím zůstane bez obrázku.)
            </p>

            <p>Na otázku "Jsme schopni určit plochu libovolné podmnožiny?" odpovídáme "ne",
            a to znamená, že (z principu)&nbsp;
            <strong>nejsme schopni přiřadit pravděpodobnost každé podmnožině {"$\\Omega$"}.</strong>
            </p>

            <p>Pro spoustu množin to ale pořád jde. Ty množiny, pro které to jde, nazveme jevovým polem.
            A pouze tyto množiny budeme označovat jako jevy. V kontextu množin reálných
            čísel {"$(\\mathbb{R}, \\mathbb{R}^2, \\mathbb{R}^3, \\ldots)$"} se jim říká Borelovské množiny (dimenze 1, 2, 3, ...).
            </p>
            {/* todo footnote */}

            <p>Kdyby k této komplikaci nedocházelo, tak bychom nejspíš nemuseli pojem
            jevové pole vůbec zavádět - vždy by se implicitně rozumělo, že pravděpodobnost je definována
            pro všechny podmnožiny. Teď ale naráz potřebujeme explicitně specifikovat, na jakých množinách
            pravděpodobnost definovaná je - a to je právě jevové pole.
            </p>

            <h2 id="evspace-advice">Jak moc se jevového pole bát?</h2>

            <p>V aplikacích vůbec.</p>

            <p>Praktické aplikace a běžné úlohy mají tu vlastnost, že 1) implikují nějaké konkrétní jevové pole
            (u diskrétních rozdělení je to {"$\\mathcal{P}(\\Omega)$"}, u spojitých rozdělení na reálných číslech jsou to
            Borelovské množiny) a 2) že přirozeně počítáme pravděpodobnost jen na množinách, které se v tomto jevovém poli nacházejí.
            Toto je splněno, aniž bychom se o tom museli nějak explicitně starat - protože např. najít a specifikovat
            neborelovskou množinu je poměrně složité, a taková množina se nevyskytne "přirozeně" v běžných úlohách.
            </p>

            <p>Pokud bychom ale řešili úlohy z teorie míry/pravděpodobnosti,
            pak bychom už potřebovali být s jevovým polem kamarádi (znát jeho vlastnosti, umět s ním počítat, atd.)
            </p>

            <p><strong>Praktická rada</strong>:</p>

            <p>
            Jevové pole se obvykle značí {"$\\mathcal{A}$"} nebo {"$\\mathcal{F}$"}.
            Teď už víte, že je to nějaký soubor podmnožin množiny {"$\\Omega$"} - tedy soubor jevů - a na těchto jevech
            je definována pravděpodobnost.
            Kdykoli ho v nějakém textu potkáte, představte si místo něj množinu všech
            podmnožin množiny {"$\\Omega$"}, tedy potenční množinu {"$\\mathcal{P}(\\Omega)$"}. Detailnější představu
            nebudete potřebovat, dokud se nezačnete zabývat teorií pravděpodobnosti na pokročilejší úrovni.
            </p>


            <h2 id="evspace-example-points">Dodatek</h2>

            <p>Aby nevznikla mýlka, ukážeme ještě jeden případ. Pokračujme v našem příkladu s detektorem
            a zachycováním fotonů. Představme si, že fotony z nějakého důvodu konsistentně dopadají přesně do
            dvou bodů na čipu - buď do jednoho, nebo do druhého, a to stejně často.
            Mohli bychom zúžit naši množinu elementárních jevů na tyto dva body - zde ji ale ponecháme nezměněnou,
            pořád budeme mít {"$\\Omega = \\langle 0, 1 \\rangle \\times \\langle 0, 1 \\rangle$"}:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_krizky_1} style={{width: "25%"}}/>
            </div>

            <p>
            Pro ony dva body si definujeme pomocná čísla {"$p_1 = 0.5$"} a {"$p_2 = 0.5$"}:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_krizky_2} style={{width: "25%"}}/>
            </div>

            <p>Teď definujeme pravděpodobnost pro <strong>každou</strong> podmnožinu {"$M \\subseteq \\Omega$"} takto:</p>
            <ul>
                <li>{"$P(M) = p_1 = 0.5$"} pokud množina {"$M$"} obsahuje levý bod a zároveň neobsahuje pravý bod.</li>
                <li>{"$P(M) = p_2 = 0.5$"} pokud množina {"$M$"} obsahuje pravý bod a zároveň neobsahuje levý bod.</li>
                <li>{"$P(M) = 0$"} pokud množina {"$M$"} neobsahuje ani jeden z bodů.</li>
                <li>{"$P(M) = p_1 + p_2 = 1.0$"} pokud množina {"$M$"} obsahuje oba body.</li>
            </ul>

            <p>Tímto jsme skutečně definovali pravděpodobnost pro jakoukoli podmnožinu, protože každá podmnožina
                spadá právě do jedné z těchto 4 kategorií - viz případy A, B, C, D na obrázku níže:
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_krizky_3} style={{width: "95%"}}/>
            </div>

            <p>
            Zde tedy nenastává 'problém' s jevovým polem - jevové pole je množina {"$\\mathcal{P}(\\Omega)$"} všech podmnožin.
            Je vidět, že onen 'problém' nenastává vždy, a není dán jen tím, že  {"$\\Omega$"} je
            nespočetné {"$(\\mathbb{R}, \\mathbb{R}^2, \\ldots )$"}, ale záleží na
            konkrétním rozdělení pravděpodobnosti které chceme definovat. Stejnoměrné rozdělení na čtverci
            na {"$\\mathcal{P}(\\Omega)$"} definované být nemohlo (a potřebovalo menší jevové pole),
            ale toto naše "bodové" rozdělení na {"$\\mathcal{P}(\\Omega)$"} definované být klidně může (a je).
            </p>

            <p>Pozn.: Zároveň si povšimněte podobností s případem házení kostkou na začátku - je to v podstatě stejná konstrukce.
            Kdybychom měli šest bodů dopadu, označených 1 až 6, psali bychom {"$P(A) = \\sum \\{ p_i \\mid i \\in A \\}$"},
            což je identický definiční vztah. Rozdíl je pouze v {"$\\Omega$"} - v případě čtverce máme v {"$\\Omega$"}
            nekonečně mnoho bodů (elem. jevů) navíc (kromě těch šesti), které ale nemohou nastat. To je OK - jak jsme
            si ukázali už v kapitole "Elementární jevy". Rozdíl je i v tom, že jevové pole je zde tvořeno
            nespočetným množstvím jevů, na rozdíl od 64 jevů.
            </p>

            <p><i>
            Zbývající část textu se zabývá vlastnostmi jevového pole, a nemusíte ji číst,
            pokud se budete řídit radou výše. Není ale zase tak těžká, takže pokud máte chuť, směle do čtení.
            </i></p>


            <h2 id="evspace-properties">Vlastnosti jevového pole</h2>

            <p>
            Jevy jsou ty 'věci', na kterých je primárně zavedena a definována pravděpodobnost.
            Pokud říkáme, že množina všech jevů (jevové pole) je množina všech podmnožin, potom vlastně
            říkáme, že pravděpodobnost můžeme spočítat pro každou libovolnou podmnožinu {"$\\Omega$"}.
            A to je super - ideálně je to to, co bychom chtěli. Mnohdy to jde, ale někdy ne.
            Teď budeme zkoumat, jak to vypadá, když to nejde.
            </p>

            <p>Nechť jevové pole není množina všech podmnožin {"$\\Omega$"}.
            Pak bude existovat nějaká podmnožina {"$A' \\subset \\Omega$"}, pro kterou
            není definována pravděpodobnost: nemůžeme spočítat {"$P(A')$"}.
            Množina {"$A'$"} není jev. Ukažme si to na příkladu:
            </p>

            <table className="simple-table">
                <tbody>
                    <tr>
                        <td>{"$\\Omega$"}</td><td>{"$\\{1, 2, 3\\}$"}</td>
                    </tr>
                    <tr>
                        <td>jevové pole {"$\\mathcal{A}$"}</td><td>{"$\\big\\{ \\{\\}, \\{1\\}, \\{2,3\\}, \\{1, 2, 3\\}\\big\\}$"}</td>
                    </tr>
                    <tr>
                        <td>pravděpodobnost</td>
                        <td>
                        <table style={{width: "100%"}}>
                            <thead>
                                <tr>
                                    <th>{"$A$"}</th><th>{"$P(A)$"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>{"$\\{\\}$"}</td><td>0</td></tr>
                                <tr><td>{"$\\{1\\}$"}</td><td>0.2</td></tr>
                                <tr><td>{"$\\{2, 3\\}$"}</td><td>0.8</td></tr>
                                <tr><td>{"$\\{1, 2, 3\\}$"}</td><td>1</td></tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>Dejme tomu, že chceme spočítat pravděpodobnost množiny {"$A' = \\{1, 2\\}$"}:
            {`$$
                P(\\{1, 2\\}) = ?
            $$`}
            Nemůžeme: Množina {"$\\{1, 2\\}$"} není jevem = není v jevovém poli = není v definičním oboru pravděpodobnosti {"$P$"};
            pravděpodobnost pro ni není definována.
            </p>

            <p>Když jevové pole {"$\\mathcal{A}$"} není množina všech podmnožin,
            a my 'na slepo' vybereme nějakou podmnožinu {"$A' \\subset \\Omega$"},
            pak si nemůžeme být jistí, zda můžeme vyhodnotit pravděpodobnost {"$P(A')$"}, dokud neověříme,
            že množina {"$A'$"} je v jevovém poli, tj. zda {"$A' \\in \\mathcal{A}$"}.
            </p>

            <p>Když používáme pravděpodobnost, běžně se spoléháme na její vlastnosti (které si ukážeme až v dalších kapitolách,
            ale které intuitivně stejně tušíme) - například, že pokud dva jevy jsou disjunktní, pak pravděpodobnost jejich sjednocení
            je součtem jejich pravděpodobností (aditivita):
            {`$$
                P(A \\cup B) = P(A) + P(B) \\quad \\text{pro } A \\cap B = \\emptyset.
            $$`}
            </p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_additive} style={{width: "85%"}}/>
            </div>

            <p>
            Na obrázku je jev {"$A$"} "padlo číslo menší rovno dvěma", jev {"$B$"} "padlo číslo, které je násobkem tří" a
            jev {"$A \\cup B$"} "padlo číslo, které je menší rovno dvěma nebo násobkem tří".
            </p>

            <p>U diskrétního rozdělení aditivita hezky vychází (viz obrázek), a je vlastně vyjádřením
            asociativního zákona pro sčítání. U spojitého příkladu (plochy) to funguje stejně:
            Velikost plochy oblasti, která sestává ze dvou disjunktních částí, je součtem ploch jednotlivých částí.
            </p>

            <p>Zpátky k jevovému poli: Vezměme dvě množiny {"$A \\subset \\Omega$"} a {"$B \\subset \\Omega$"} z jevového pole.
            Chtěli bychom napsat, že pokud {"$A \\cup B = \\emptyset$"}, pak vždy platí:
            {`$$
                P(A \\cup B) = P(A) + P(B).
            $$`}
            Pravá strana rovnice je dobře definovaná - protože {"$A$"} a {"$B$"} jsou v jevovém poli, můžeme
            pracovat s jejich pravděpodobnostmi {"$P(A)$"} a {"$P(B)$"}.
            Co ale levá strana rovnice?
            Jak si ale můžeme být jistí, že množina {"$A \\cup B$"} je v jevovém poli? Že můžeme spočítat pravděpodobnost
            {"$P(A \\cup B)$"}?
            Jistí si být nemůžeme... Do vzorce tohoto typu bychom museli připsat dodatek
            "pokud {"$A \\cup B \\in \\mathcal{A}$"}"...
            </p>
            <p>
            ...což je extrémně nepraktické...
            </p>
            <p>
            ...leda bychom řekli, že budeme pracovat jen s takovými systémy podmnožin, kde je tohle automaticky vždy splněno
            - tedy že abychom nějaký systém množin {"$\\mathcal{A}$"} označili za jevové pole,
            musíme mít jistotu, že je 'uzavřený na sjednocení': Že pro každé jeho dvě
            množiny {"$A, B \\in \\mathcal{A}$"} platí, že i jejich
            sjednocení {"$A \\cup B$"} je prvkem tohoto jevového pole: {"$A \\cup B \\in \\mathcal{A}$"}.
            </p>
            <p>Sjednocení není nijak privilegované mezi množinovými operacemi: Stejně tak cítíme,
            že když jev {"$A$"} má pravděpodobnost {"$p$"}, pak opačný jev {"$A^\\complement$"} by
            měl mít pravděpodobnost {"$1 - p$"}:
            </p>


            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_complement} style={{width: "85%"}}/>
            </div>
            <p>Chceme tedy říct, že pro každé {"$A \\in \\mathcal{A}$"} musí být:
            {`$$
                P(A^\\complement) = 1 - P(A).
            $$`}
            Abychom se na tuto rovnici mohli spolehnout, opět musíme mít jistotu,
            že levá strana - množina {"$A^\\complement$"} - je v jevovém poli: {"$A^\\complement \\in \\mathcal{A}$"}.
            </p>

            <p>Není tedy překvapením, že budeme požadovat aby v jevovém poli byly i rozdíly jeho množin:
            {"$A \\setminus B \\in \\mathcal{A}$"}, a také průniky: {"$A \\cap B \\in \\mathcal{A}$"}, ...
            a obecně výsledky všech 16 množinových operací, které se dvěma množinami můžeme provést
            - viz kapitola "Množiny (2)" - diagram ke konci kapitoly.
            </p>

            <p>Průnik dvou disjunktních množin je {"$\\emptyset$"}, a sjednocení množiny se svým doplňkem
            je {"$A \\cup A^\\complement = \\Omega$"}. V jevovém poli tedy vždy budeme mít množiny {"$\\emptyset$"} a {"$\\Omega$"}.
            </p>

            <p>Teď bychom mohli chtít napsat definici jevového pole: Je to systém podmnožin množiny {"$\\Omega$"}, takový, že
            splňuje:
            {`$$\\begin{align*}
                \\emptyset & \\in \\mathcal{A}, \\\\
                \\Omega & \\in \\mathcal{A}, \\\\
                A^\\complement & \\in \\mathcal{A} \\quad \\text{pro každé } A \\in \\mathcal{A}, \\\\
                A \\cup B & \\in \\mathcal{A} \\quad \\text{pro každé } A, B \\in \\mathcal{A}, \\\\
                A \\cap B & \\in \\mathcal{A} \\quad \\text{pro každé } A, B \\in \\mathcal{A}, \\\\
                A \\setminus B & \\in \\mathcal{A} \\quad \\text{pro každé } A, B \\in \\mathcal{A}. \\\\
            \\end{align*}$$`}
            </p>
            <p>Jak už je ale v matematice zvykem, zapisuje se pouze minimální systém požadavků,
            ze kterého se ostatní dají odvodit. V tomto případě stačí požadovat:
            {`$$\\begin{align}
                \\Omega & \\in \\mathcal{A}, \\\\
                A^\\complement & \\in \\mathcal{A} \\quad \\text{pro každé } A \\in \\mathcal{A}, \\\\
                A \\cup B & \\in \\mathcal{A} \\quad \\text{pro každé } A, B \\in \\mathcal{A}. \\\\
            \\end{align}$$`}
            </p>
            <p>
            Pokud toto platí, tak {"$\\Omega^C \\in \\mathcal{A}$"}, ale {"$\\Omega^C = \\emptyset$"}, a tedy {"$\\emptyset \\in \\mathcal{A}$"}.
            Zároveň {"$A \\cap B = (A^C \\cup B^C)^C \\in \\mathcal{A}$"} a tedy {"$A \\cap B \\in \\mathcal{A}$"}.
            Množinový rozdíl: {"$A \\setminus B = A \\cap B^C \\in \\mathcal{A}$"}.
            </p>

            <p>Příklad: Systém množin v tomto případě je jevové pole:</p>
            <table className="simple-table">
                <tbody>
                    <tr>
                        <td>{"$\\Omega$"}</td><td>{"$\\{1, 2, 3\\}$"}</td>
                    </tr>
                    <tr>
                        <td>systém množin {"$\\mathcal{A}$"}</td><td>{"$\\big\\{ \\{\\}, \\{1\\}, \\{2,3\\}, \\{1, 2, 3\\}\\big\\}$"}</td>
                    </tr>
                </tbody>
            </table>
            <p>Můžete si to ověřit:<br/>
            1) {"$\\Omega = \\{1, 2, 3\\}$"} je v systému přítomná.<br/>
            2) Doplňky:
                {"$\\{\\}^C = \\{1, 2, 3\\}$"},
                {"$\\{1\\}^C = \\{2, 3\\}$"},
                {"$\\{2, 3\\}^C = \\{1\\}$"},
                {"$\\{1, 2, 3\\}^C = \\{\\}$"} jsou v všechny systému přítomné.<br/>
            3) Sjednocení: {"$\\{1\\} \\cup \\{2, 3\\} = \\{1, 2, 3\\}$"} je v systému přítomné. Sjednocení
                {"$\\{\\} \\cup A = A$"} je vždy v systému přítomné, a stejně tak {"$\\{1, 2, 3\\} \\cup A = \\{1, 2, 3\\}$"}.
            </p>

            <p>Příklad: Systém množin v tomto případě není jevové pole:</p>
            <table className="simple-table">
                <tbody>
                    <tr>
                        <td>{"$\\Omega$"}</td><td>{"$\\{1, 2, 3\\}$"}</td>
                    </tr>
                    <tr>
                        <td>systém množin {"$\\mathcal{A}$"}</td><td>{"$\\big\\{ \\{\\}, \\{1\\}, \\{2\\}, \\{2,3\\}, \\{1, 3\\}, \\{1, 2, 3\\}\\big\\}$"}</td>
                    </tr>
                </tbody>
            </table>
            <p>
            Vidíme to např. z toho, že {"$\\{1\\} \\cup \\{2\\} = \\{1, 2\\}$"} není
            v systému přítomné: {"$\\{1, 2\\} \\notin \\mathcal{A}$"}. Také si můžeme všimnout,
            že {"$\\{2,3\\} \\setminus \\{2\\} = \\{3\\} \\notin \\mathcal{A}$"}.
            </p>

            <p>
            Systému množin, který splňuje výše uvedená tři kritéria, se říká <strong>algebra</strong>;
            odtud pochází označení písmenem {"$\\mathcal{A}$"}.
            </p>

            <p>Pro sjednocení platí, že nejen sjednocení každé dvojice, ale i <strong>sjednocení každé n-tice</strong> množin
            je prvkem {"$\\mathcal{A}$"}, protože závorkujeme po dvou - při sjednocování více množin v každém kroku sjednocujeme jen dvě množiny,
            a tento mezivýsledek (sjednocení dvou množin) je dle předpokladu prvkem {"$\\mathcal{A}$"}:
            {`$$\\begin{align}
                A_1 \\cup A_2 \\cup A_3 &= (A_1 \\cup A_2) \\cup A_3 \\\\
                A_1 \\cup A_2 \\cup A_3 \\cup A_4 &= ((A_1 \\cup A_2) \\cup A_3) \\cup A_4 \\\\
                                            & \\cdots
            \\end{align}$$`}
            </p>
            <p>Stejný princip platí i pro průnik. Můžeme to zapsat takto:
            {`$$\\begin{align*}
                A_1 \\cup A_2 \\ldots \\cup A_n &= \\bigcup_{i=1}^{n} A_i \\in \\mathcal{A}, \\\\
                A_1 \\cap A_2 \\ldots \\cap A_n &= \\bigcap_{i=1}^{n} A_i \\in \\mathcal{A}.
            \\end{align*}$$`}
            </p>

            <p>Vzpomeňte, že pro dvě disjunktní množiny jsme měli vztah
            {`$$
                P(A \\cup B) = P(A) + P(B).
            $$`}
            Analogicky, pro n-tici vzájemně disjunktních množin platí:
            {`$$\\begin{align*}
                P(A_1 \\cup A_2 \\cup \\cdots \\cup A_n) & = P(A_1) + P(A_2) + \\cdots + P(A_n), \\quad \\mathrm{neboli} \\\\
                P\\Big( \\bigcup_{i=1}^{n} A_i \\Big) & = \\sum_{i=1}^{n} P(A_i).
            \\end{align*}$$`}
            </p>

            <p>Poslední krok, který potřebujeme udělat, je přejít k takzvané {"$\\sigma$"}-algebře (sigma-algebře).</p>

            <p>Dejme tomu, že náš pravděpodobnostní model je model radioaktivního rozpadu.
            Máme (velikou) množinu atomů, a víme že poločas rozpadu je 1 rok.
            Náhodně vybraný atom má pravděpodobnost {"$\\frac{1}{2}$"}, že se rozpadne během prvního roku,
            pravděpodobnost {"$\\frac{1}{4}$"}, že se rozpadne během druhého roku,
            pravděpodobnost {"$\\frac{1}{8}$"} že se rozpadne během třetího roku, atd.
            </p>

            <p>Za množinu elementárních jevů {"$\\Omega$"} si zvolíme čísla všech
            roků: {"$\\Omega = \\mathbb{N} = \\{1, 2, 3, 4, 5, \\ldots \\}$"}.
            </p>
            <p>Pomocná čísla zvolíme jako {"$p_i = \\frac{1}{2^i}$"}, a pravděpodobnost libovolné podmnožiny
            {"$A \\subseteq \\Omega$"} definujeme obvyklým způsobem:
            {`$$
                P(A) = \\sum \\big\\{ p_i \\mid i \\in A \\big\\} = \\sum_{i \\in A} p_i.
            $$`}
            Tato množina může být nekonečná, takže se může jednat o nekonečný součet (řadu). My víme,
            že konverguje, protože (geometrická) řada
            {`$$
                \\sum_{i=1}^{\\infty} p_i = \\sum_{i=1}^{\\infty} \\frac{1}{2^i} = \\frac{1}{2} + \\frac{1}{4} + \\frac{1}{8} + \\ldots = 1
            $$`}
            absolutně konverguje, a každá 'podřada' tedy také konverguje. Definice je tedy OK.
            </p>
            <p>
            Máme vlastně stejný model jako při hodu kostkou, jen nemáme možných výsledků šest, ale nekonečně (spočetně) mnoho.
            </p>
            <p>Jevy, které sestávají z jednoho čísla, vypadají takto: {"$\\{1\\}$"}, {"$\\{2\\}$"}, {"$\\{3\\}$"}, ...
            Dejme tomu, že nás zajímá jev, že k rozpadu dojde v sudém roce - tedy jev {"$A = \\{2, 4, 6, 8, \\ldots \\}$"}.
            Pravděpodobnost tohoto jevu je
            {`$$
                P(A) = \\sum_{i \\in A} p_i = p_2 + p_4 + p_6 + p_8 + \\ldots = \\frac{1}{4} + \\frac{1}{16} + \\frac{1}{64} + \\ldots = \\frac{1}{3}.
            $$`}
            Vidíme, že množina {"$A = \\{2, 4, 6, 8, \\ldots \\}$"} je sjednocením nekonečně mnoha disjunktních množin:
            {`$$
                A = \\{2, 4, 6, 8, \\ldots \\} = \\{2\\} \\cup \\{4\\} \\cup \\{6\\}  \\cup \\ldots = \\bigcup_{i=1}^{\\infty} \\{2i\\}.
            $$`}
            Očekáváme, že bude platit spočetná verze aditivity pravděpodobnosti (tedy nejen pro <i>dvě</i> disjunktní množiny a pro {"$n$"} disjunktních množin,
            ale i pro <i>spočetně mnoho</i> /pro nekonečnou posloupnost/ disjunktních množin):
            {`$$
                P\\Big(\\bigcup_{i=1}^{\\infty} A_i\\Big) = \\sum_{i=1}^{\\infty} P(A_i).
            $$`}
            Kdyby naše jevové pole nebyla množina všech podmnožin, pak by opět
            vyvstala otázka, zda množina {"$\\bigcup_{i=1}^{\\infty} A_i$"} je prvkem jevového pole
            (z přítomnosti konečných sjednocení neplyne přítomnost nekonečného sjednocení).
            Abychom tomuto předešli, budeme požadovat, aby jevové pole bylo uzavřené na spočetná sjednocení,
            a tím dostáváme finální podobu požadavků na jevové pole:

            {`$$\\begin{align}
                \\Omega & \\in \\mathcal{A}, \\\\
                A^\\complement & \\in \\mathcal{A}  && \\text{pro každé } A \\in \\mathcal{A}, \\\\
                \\bigcup_{i=1}^{\\infty} A_i & \\in \\mathcal{A}  &&\\text{pro každou posloupnost množin } \\{A_i\\}_{i=1}^{\\infty}, A_i \\in \\mathcal{A}.
            \\end{align}$$`}

            </p>

            <p>Původní (nahrazené) pravidlo pro sjednocení dvou množin {"$A \\cup B \\in \\mathcal{A}$"} je v tomto elegantně
            (i když ne úplně intuitivně) zahrnuto: Máme-li množiny {"$A \\in \\mathcal{A}$"} a {"$B \\in \\mathcal{A}$"},
            pak za posloupnost {"$\\{A_i\\}_{i=1}^{\\infty}$"} zvolíme posloupnost
            {`$$
                \\{A, B, \\emptyset, \\emptyset, \\cdots \\},
            $$`}
            jejíž prvky jsou všechny v {"$\\mathcal{A}$"} (protože kromě {"$A$"} a {"$B$"} je i {"$\\emptyset \\in \\mathcal{A}$"}), pro kterou platí:
            {`$$
                \\bigcup_{i=1}^{\\infty} A_i = A \\cup B \\cup \\emptyset \\cup \\emptyset \\cup \\cdots = A \\cup B.
            $$`}
            Dle požadavku {"$\\bigcup_{i=1}^{\\infty} A_i \\in \\mathcal{A}$"} je tedy {"$A \\cup B \\in \\mathcal{A}$"}.
            </p>

            <p>De Morganovy zákony pro spočetný případ říkají, že
            {`$$
                \\Big(\\bigcap_{i=1}^{\\infty} A_i\\Big) = \\Big( \\bigcup_{i=1}^{\\infty} A_i^\\complement \\Big)^\\complement \\in \\mathcal{A},
            $$`}
            tedy i <i>spočetný průnik</i> jevů je v jevovém poli. To je velmi užitečné:</p>

            <div style={{textAlign: "center", paddingBottom: 10}}>
                <img src={jevove_pole_limit} style={{width: "50%"}}/>
            </div>

            <p>Pravděpodobnost složitější množiny (žluté) totiž můžeme definovat jako limitu posloupnosti pravděpodobností
            jednodušších množin (šedých), které ji postupně přesněji a přesněji pokrývají:
            {`$$
                P(A) = P\\Big(\\bigcap_{i=1}^{\\infty} A_i\\Big) = \\lim_{i \\to \\infty} P(A_i).
            $$`}
            Zvyšující se přesnost pokrytí je dána tím, že {"$A_1 \\supseteq A_2 \\supseteq A_3 \\supseteq \\cdots$"},
            a 'v limitě' platí {"$\\bigcap_{i=1}^{\\infty} A_i = A$"}.
            Toto je další důvod, proč se v jevovém poli požaduje uzavřenost na spočetné (nikoli jen konečné) množinové operace.
            </p>
            <p>
            Jevové pole můžete dále prozkoumat v&nbsp;
            <ELink href={"https://is.muni.cz/do/rect/el/estud/prif/ps13/prav_stat/web_1/pages/01-kolmogorova-definice.html#definice-jev-pole"}>
                pokročilejším textu
            </ELink>.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}

