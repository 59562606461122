import React from 'react';
import { ELink } from './ExternalLink';

const PortraitJan = require('./assets/images/portrait_jan.jpg');
const PortraitDominik = require('./assets/images/portrait_dominik.jpg');

export function AuthorProfiles()  {

    return (
        <div className="chapter-container">
        <div className="centered-content">
            <h1>Autoři</h1>

            <div className='author-card'>
                <div className='author-part-left'>
                    <h2 className='author-heading'>Jan Novotný</h2>
                    <p className='author-titles'>(Ing. Bc. Jan Novotný)</p>
                    <img src={PortraitJan} style={{maxWidth: 150}} alt={"Portrétní fotografie autora"} />
                </div>
                <div className='author-part-right'>
                    <p style={{paddingTop: 0, marginTop: 0}}>
                    Mým oborem je aplikovaná matematika, specificky optimalizace a&nbsp;pravděpodobnost + statistika.
                    Přes deset let pracuji v praxi, kde implementuji matematické modely pro optimalizaci
                    v logistice, výrobě a&nbsp;řízení dopravy. Moje pracovní pozice zahrnuje rovněž velkou dávku IT.
                    Cvičím matematický předmět na ÚSI VUT v Brně.
                    </p>
                    <p>
                    Věřím, že mojí silnou stránkou je schopnost vysvětlit
                    matematické koncepty pochopitelným a zajímavým způsobem.
                    </p>
                    <p>
                    Jsem absolventem oborů Obecná fyzika na Př.F. MU (Bc.) a Matematické inženýrství na FSI VUT v Brně (Ing.).
                    </p>
                </div>
            </div>

            <div className='author-card'>
                <div className='author-part-left'>
                    <h2 className='author-heading'>Dominik Heger</h2>
                    <p className='author-titles'>(doc. Mgr. Dominik Heger, Ph.D.)</p>
                    <img src={PortraitDominik} style={{maxWidth: 150}} alt={"Portrétní fotografie autora"} />
                </div>
                <div className='author-part-right'>
                    <p style={{paddingTop: 0, marginTop: 0}}>
                    Vystudoval jsem chemii na Masarykově univerzitě v Brně.
                    Svůj magisterský i Ph.D. titul jsem získal pod vedením prof. Petra Klána.
                    Zatímco diplomová práce (2002) s názvem <em>Synthesis and energy transfer of bichromophores </em>
                    vedla k zavedení fotoodstranitelné ochranné skupiny (2,5-dimethylfenacyl),
                    Ph.D. disertace (2006) byla zaměřena na spektroskopii organických sloučenin na ledu,
                    konkrétně na hodnocení agregací, změny pH a solvatochromního chování zmrazených sloučenin.
                    Během studia jsem se připojil ke skupinám Andreje Loupyho (Université Paris XI, Orsay, Francie),
                    Michaela Greenleavea (University of Missouri, Columbia, MO, USA) a Jakoba Wirze (Basel Universität, Švýcarsko).
                    </p>
                    <p>
                    Můj postdoktorandský pobyt na Basel Universität (2006-2007) byl garantován Jakobem Wirzem
                    a zahrnoval především zkoumání různých fotoodstranitelných ochranných skupin s časově rozlišenými technikami.
                    Po odchodu do důchodu nám Joggi velkoryse dovolil převézt své vybavení do Brna a umožnil tak plynulé pokračování výzkumu.
                    Od roku 2007 působím v rámci fotochemických skupin na Masarykově univerzitě v Brně.
                    </p>
                    <p>
                    Při zpracování dat ve výzkumu kladu důraz na smysluplné a korektní využití vhodných statistických metod.
                    V této oblasti mě inspiroval především
                    <ELink href={"https://www.stat.berkeley.edu/~stark/SticiGui/index.htm"}>
                        {" kurz z Berkeley"}
                    </ELink>
                    , jehož autorem je Philip B. Stark,
                    a dále jsem si oblíbil knihy, jejichž autory jsou Jerrold H. Zar, David Freedman, Robert Pisani a Roger Purves.
                    </p>

                    <p>
                    <ELink href={"https://vesmir.cz/cz/casopis/archiv-casopisu/2019/cislo-1/dejte-k-ledu-ke-kteremu.html"}>
                        {"Vesmír.cz: Článek o vlastnostech ledu."}
                    </ELink>
                    </p>
                    <p>
                    <ELink href={"https://hegerd.sci.muni.cz/"}>
                        {"Stránka mého výzkumného týmu."}
                    </ELink>
                    </p>
                </div>
            </div>

        </div>
        </div>
    );
}
