import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const ProbabilityIntroMeta = {
    title: "Pravděpodobnost",
    shortTitle: "Pravděpodobnost",
    path: Path.probability_intro,
    element: (sectionNumber:string) => <ProbabilityIntro sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function ProbabilityIntro({sectionNumber}: {sectionNumber: string}) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={ProbabilityIntroMeta.title}>

            <p>
            Teorie pravděpodobnosti představuje jednotný rámec pro popis
            veškerých náhodných pokusů - od hodu mincí a kostkou, přes výběry
            s opakováním a bez opakování až po měření fyzikálních veličin,
            kvantovou fyziku a náhodné procesy. Jednotný
            rámec znamená, že ve všech těchto případech vlastně pracujeme stejně,
            i když na první pohled řešíme jinou úlohu.
            </p>

            <Footer/>
        </ChapterWrapper>
    );
}
