import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { MathJaxWrapper } from './MathJaxWrapper';
import { MathJax } from 'better-react-mathjax';

export type TOCProps = Array<{ id: string; text: string }>;


export function TOC({ headers }: { headers: TOCProps }) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleClick = (id: string) => {
        const element = document.getElementById(id);
        // console.log("Scrolling to", id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <MathJaxWrapper>
        <MathJax dynamic={true}>
        <div className={`toc ${isCollapsed ? 'collapsed' : ''}`} style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <div>
            <button onClick={toggleCollapse} className="toc-toggle" data-tooltip-id="tooltip-toc-ce" data-tooltip-content={isCollapsed ? 'Rozbalit obsah' : 'Sbalit obsah'}>
                {isCollapsed ? '◀' : '▶'}
            </button>
            <Tooltip id="tooltip-toc-ce"/>
            </div>
            {!isCollapsed && (
                <div>
                    <p>Obsah:</p>
                    <ul>
                        {headers.map((header) => (
                            <li key={header.id} onClick={() => handleClick(header.id)}>
                                {header.text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

export function defineTOCElements(
    chapterRef: React.RefObject<HTMLDivElement>,
    toc: string[],
    setHeaders: React.Dispatch<React.SetStateAction<Array<{ id: string; text: string }>>>) {
    const headersArray: Array<{ id: string; text: string }> = [];
    const chapterElement = chapterRef.current;
    if (chapterElement) {
        const headersElements = chapterElement.querySelectorAll('h1, h2, h3');
        headersElements.forEach((header) => {
            if (toc.includes(header.id)) {
                headersArray.push({ id: header.id, text: header.textContent || ""});
            }
        });
    }
    setHeaders(headersArray);
};
