import React from 'react';

import { Path } from '~/paths';
import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';

export const MathSectionMeta = {
    title: "Matematický aparát",
    shortTitle: "Matematický aparát",
    path: Path.math_section,
    element: (sectionNumber:string) => <MathSection sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

export function MathSection({sectionNumber}: {sectionNumber: string}) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={MathSectionMeta.title}>

            <p>
            Základní matematický aparát v rozsahu <i>množiny, kartézský součin, rozklad, zobrazení</i>&nbsp;
            je extrémně užitečný pro chápání vztahů mezi jevy, pravděpodobnostmi, náhodnými veličinami atd.
            Tato sekce ho shrnuje pro případ, že by si ho někdo potřeboval osvěžit. Dále přidáváme
            kapitolu o spočetnosti, což je pokročilejší koncept, který ale v kontextu pravděpodobnosti
            hraje významnou roli.
            </p>
            <Footer/>
        </ChapterWrapper>
    );
}

